import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid"
import { Box } from "@mui/material"
import { HomeLink } from './routes';
import { Route } from "react-router-dom";
import ActionBar from '../components/ActionBar';
import Customers from './customers/Customers';
import AddEditCustomer from './customers/add-edit-customer/AddEditCustomer';
import Typography from '@mui/material/Typography';
import { SnackbarProvider } from 'notistack';
import { getConfiguration } from '../services/ApiService';

const Main = (props) => {

	const {setApplicationRoutes} = props;

	useEffect(() => {
		const applicationRoutes = [
			{
				path: `/add-edit-customer/:customerId`,
				element: 
					<WrappedRouteElement
					 element={<AddEditCustomer />} />
			},
			{ 
				path:`${HomeLink.route}`,
				sx: { height: "inherit" }, 
				element:
					<WrappedRouteElement
					 element={<Customers />} />
			},
		  ];
		  setApplicationRoutes(applicationRoutes);
	  }, []);

	return (<div>loading...</div>);
}

const WrappedRouteElement = ({element}) => {

	const [actionInfos, setActionInfos] = useState({ actions: [], actionClicked: null, title: null });
	const [title, setTitle] = useState("");

	const [config, setConfig] = useState({});
	useEffect(() => {
		getConfiguration(setConfig);
	}, []);

	const getCurrentYear = () => {
		return new Date().getFullYear();
	};

	return (
		<SnackbarProvider maxSnack={10}>
			<Box sx={{ padding: "16px", height: "100%", overflow: "hidden"}} >
				<main style={{width: "100%", height: "100%", position: 'relative'}}>
					<Grid container>
						<Grid item xs={3}>
							<Typography variant="h5">{title}</Typography>
						</Grid>
						<Grid item xs={9}>
							<ActionBar
								actionInfos={actionInfos}
								setActionInfos={setActionInfos}
							/>

						</Grid>
					</Grid>
					{React.cloneElement(element, {actionInfos, setActionInfos, title, setTitle, config})}
				</main>
			</Box>
		</SnackbarProvider>
	);
}

export default Main;