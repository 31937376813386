import React, { useState } from 'react';
import { Box } from '@mui/material';
import FormTemplate from './FormTemplate';
import OEOptionsForm from './form-definitions/OEOptionsForm';
import ProductsChooser from '../ProductsChooser';
const OEOptions = ({ options, setOption, errors, isDisplay, showCustomerOptions, customerOptions, defaultOptions }) => {

    const [state, setState] = useState({ open: false, accessor: '' });
    const handleOpen = (accessorValue) => setState({ open: true, accessor: accessorValue });
    const handleClose = () => setState({ open: false, accessor: '' });

    const handleSelection = (selection) => {
        const updatedOpt = {
            ...options,
            [state.accessor]: selection
        };
        setOption(updatedOpt);
        handleClose();
    }

    const formDefinition = OEOptionsForm(handleOpen);

    return (
        <Box>
            <FormTemplate
                data={formDefinition}
                id="OE"
                options={options}
                errors={errors}
                isDisplay={isDisplay}
                setOption={setOption}
                showCustomerOptions={showCustomerOptions}
                customerOptions={customerOptions}
                defaultOptions={defaultOptions}
            />
            <ProductsChooser initialSelection={options[state.accessor]} open={state.open} handleClose={handleClose} handleSelection={handleSelection}/>
        </Box>
    );
}

export default OEOptions;