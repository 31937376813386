import React, { useState, useMemo, useEffect } from 'react';

import { AuthProvider } from 'jnpsoft-react-utilities/dist/AppBar';
import Main from './pages/Main'

const App = () => {

  return (
    <AuthProvider 
      render={(props) => <Main id={props.id} setApplicationRoutes={props.setApplicationRoutes}/>}
      showUtilityBar={false}/>
  );
}

export default App;