
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import ProductLineColumnDefinitions from './column-definitions/ProductLineColumnDefinitions';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import '../../styles/jnp-table.css';
import '../../styles/toolbar.css';

export default ({products, refProducts, isDisplay, title = '', handleRegion, handleYearFrom, handleYearTo, onRemoveRow, additionalColumns = []}) => {
  
  const columnDefinitions = ProductLineColumnDefinitions(isDisplay, handleRegion, handleYearFrom, handleYearTo, onRemoveRow, additionalColumns);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!isDisplay) {
      setTableData(products)
    }
    else {
      const newProducts = _.differenceWith(products, refProducts, _.isEqual);
      const missingProducts = _.differenceWith(refProducts, products, _.isEqual);

      const newTableData = [...refProducts.map(x => {
        const isDeleted = _.some(missingProducts, x);
        return {...x, rowStatus: isDeleted ? 'deleted': 'normal'}
      }),
        ...newProducts.map(x => ({...x, rowStatus: 'added'}))
      ];
      setTableData(newTableData);
    }
  }, [isDisplay, products]);

  return (
    <div style={{height: '100%'}}>
      <DivTableStyles>
        <JnpDivTable
            columns={columnDefinitions}
            data={tableData}
            defaultSortColumn="productLineName"
            title={title}
            uniqueId="oe-product-lines"
            backGroundColor="#003061"
            selectedBackgroundColor='white'
            sx={{padding:0}}
            initialAutoResetPage={false}
            isEditable={!isDisplay}
        />
      </DivTableStyles>
    </div>
  );
}