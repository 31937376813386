import { ContractFrequency, VIOConfig } from '../../../constants/DefaultOptions';

export default ()  => [
    {
        headerTitle: VIOConfig.headerTitles.contact,
        sections: [
            {
                title: VIOConfig.active.title,
                accessor: VIOConfig.active.accessor,
                input: "checkbox",
            },
            {
                title: VIOConfig.sameAsMainContact.title,
                accessor: VIOConfig.sameAsMainContact.accessor,
                input: "checkbox",
            },
            {
                title:VIOConfig.name.title,
                accessor: VIOConfig.name.accessor,
                input: "text",
                accessorDep: options => options[VIOConfig.sameAsMainContact?.accessor] ?? false
            },
            {
                title: VIOConfig.email.title,
                accessor: VIOConfig.email.accessor,
                input: "text",
                accessorDep: options => options[VIOConfig.sameAsMainContact?.accessor] ?? false
            },
            {
                title: VIOConfig.phone.title,
                accessor: VIOConfig.phone.accessor,
                input: "text",
                accessorDep: options => options[VIOConfig.sameAsMainContact?.accessor] ?? false
            },
        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.contract,
        sections: [
            {
                title: VIOConfig.contractDate.title,
                accessor: VIOConfig.contractDate.accessor,
                input: "startDate",
                type: "date",
                dateContractEndAccessor:VIOConfig.contractEndDate.accessor,
            },
            {
                title: VIOConfig.contractEndDate.title,
                accessor: VIOConfig.contractEndDate.accessor,
                input: "text",
                type: "date",
            },
            {
                title: VIOConfig.contractFrequency.title,
                accessor: VIOConfig.contractFrequency.accessor,
                input: "radio",
                value: ContractFrequency,

            },
            {
                title: VIOConfig.contractActiveForDelivery.title,
                accessor: VIOConfig.contractActiveForDelivery.accessor,
                input: "checkbox",
            },
        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.usaLightDuty,
        sections: [
            {
                title: VIOConfig.usaLightDutyActive.title,
                accessor: VIOConfig.usaLightDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.usaLightDutyNational.title,
                        accessor: VIOConfig.usaLightDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.usaLightDutyState.title,
                        accessor: VIOConfig.usaLightDutyState.accessor,
                        input: "checkbox",

                        accessorDep: VIOConfig.usaLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.usaLightDutyCounty.title,
                        accessor: VIOConfig.usaLightDutyCounty.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.usaLightDutyZip.title,
                        accessor: VIOConfig.usaLightDutyZip.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaLightDutyActive.accessor,
                    },

                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            }
        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.usaHeavyDuty,
        sections: [
            {
                title: VIOConfig.usaHeavyDutyActive.title,
                accessor: VIOConfig.usaHeavyDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.usaHeavyDutyNational.title,
                        accessor: VIOConfig.usaHeavyDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.usaHeavyDutyState.title,
                        accessor: VIOConfig.usaHeavyDutyState.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaHeavyDutyActive.accessor,

                    },
                    {
                        title: VIOConfig.usaHeavyDutyCounty.title,
                        accessor: VIOConfig.usaHeavyDutyCounty.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.usaHeavyDutyZip.title,
                        accessor: VIOConfig.usaHeavyDutyZip.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.usaHeavyDutyActive.accessor,
                    },
                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            },

        ]
    },

    {
        headerTitle: VIOConfig.headerTitles.canLightDuty,
        sections: [
            {
                title: VIOConfig.canLightDutyActive.title,
                accessor: VIOConfig.canLightDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.canLightDutyNational.title,
                        accessor: VIOConfig.canLightDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canLightDutyActive.accessor,
                    },

                    {
                        title: VIOConfig.canLightDutyProvince.title,
                        accessor: VIOConfig.canLightDutyProvince.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.canLightDutyMunicipal.title,
                        accessor: VIOConfig.canLightDutyMunicipal.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canLightDutyActive.accessor,
                    },

                    {
                        title: VIOConfig.canLightDutyPostalCode.title,
                        accessor: VIOConfig.canLightDutyPostalCode.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canLightDutyActive.accessor,
                    },
                    
                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            },

        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.canHeavyDuty,
        sections: [
            {
                title: VIOConfig.canHeavyDutyActive.title,
                accessor: VIOConfig.canHeavyDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.canHeavyDutyNational.title,
                        accessor: VIOConfig.canHeavyDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.canHeavyDutyProvince.title,
                        accessor: VIOConfig.canHeavyDutyProvince.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.canHeavyDutyMunicipal.title,
                        accessor: VIOConfig.canHeavyDutyMunicipal.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.canHeavyDutyPostalCode.title,
                        accessor: VIOConfig.canHeavyDutyPostalCode.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.canHeavyDutyActive.accessor,
                    },
                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            },
        ]
    },

    {
        headerTitle: VIOConfig.headerTitles.mexLightDuty,
        sections: [
            {
                title: VIOConfig.mexLightDutyActive.title,
                accessor: VIOConfig.mexLightDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.mexLightDutyNational.title,
                        accessor: VIOConfig.mexLightDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.mexLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.mexLightDutyState.title,
                        accessor: VIOConfig.mexLightDutyState.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.mexLightDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.mexLightDutyMunicipal.title,
                        accessor: VIOConfig.mexLightDutyMunicipal.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.mexLightDutyActive.accessor,
                    },
                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            },
            
        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.mexHeavyDuty,
        sections: [
            {
                title: VIOConfig.mexHeavyDutyActive.title,
                accessor: VIOConfig.mexHeavyDutyActive.accessor,
                input: "checkbox",
                subsections: [
                    {
                        title: VIOConfig.mexHeavyDutyNational.title,
                        accessor: VIOConfig.mexHeavyDutyNational.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.mexHeavyDutyActive.accessor,
                    },
                    {
                        title: VIOConfig.mexHeavyDutyState.title,
                        accessor: VIOConfig.mexHeavyDutyState.accessor,
                        input: "checkbox",
                        accessorDep: VIOConfig.mexHeavyDutyActive.accessor,
                    },
                ],
                labelStyle: {
                    fontSize: "12pt",
                    color: "green",
                },
                labelStyleDisabled: {
                    color: "red",
                    fontSize: "12pt",
                },
            },
           
        ]
    },
    {
        headerTitle: VIOConfig.headerTitles.additionalInfo,
        gridSize: 12,
        sections: [
            {
                title: VIOConfig.additionalInfo.title,
                accessor: VIOConfig.additionalInfo.accessor,
                input: "multilinetext",
            }
        ]
    },
];