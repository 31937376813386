
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GeneralTextField from '../GeneralTextField';
import GeneralRadioGroup from '../GeneralRadioGroup';
import GeneralCheckBox from '../GeneralCheckBox';
import GeneralProductField from '../GeneralProductField';
import ToolbarProductList from '../ToolbarProductList';
import GeneralAutocomplete from '../GeneralAutocomplete';
import { TextField } from '@mui/material';
import { reverseLengths } from '../../utils';
import { styled } from '@mui/system';
import GeneralVirtualizedAutocomplete from '../GeneralVirtualizedAutocomplete';
import moment from 'moment'
import Paper from '@mui/material/Paper';
import ProductLineTable from './ProductLineTable';

const FormTypographyLabel = styled(Typography)`
color:black;
font-weight:bold;
width:100%;
padding-right:8px;

`;

const FormTypographyDisplayResult = styled(Typography)`
    padding-left:9px;
`;

const FormTypographyDisplayPreviousValue = styled(Typography)`
color:red;
`;

const disabledLabelStyle = {
    color: "#d3d3cd",
}

const ItemHeight = '46px';
const ColumnYearFrom = 'yearFrom';
const ColumnYearTo = 'yearTo';
const controlHeight = 30;
const GridItemStyle = { paddingTop: '0px!important' };

const FormTemplate = ({ data, setOption, errors, options, isDisplay, showCustomerOptions, customerOptions, defaultOptions = {}, id }) => {
    const showPreviousValues = (options, category) => {

        if (!showCustomerOptions) {
            return false;
        }

        if (Array.isArray(options[category?.accessor] ?? "")) {
            return JSON.stringify(options[category?.accessor] ?? []) != JSON.stringify(customerOptions[category?.accessor] ?? "[]");
        }

        return options[category?.accessor] != customerOptions[category?.accessor];
    }

    const handleInputChange = (evt) => {
        const val = evt.target.value;
        const updatedObj = { ...options, additionalInfo: val };
        setOption(updatedObj);
    };

    const handleInputChangeYear = (value, index, products, yearColumn, accessor) => {
        if (!isNaN(value)) {
            const productObj = JSON.parse(JSON.stringify(products));
            productObj[index] = { ...productObj[index], [yearColumn]: value };
            const updatedObj = { ...options, [accessor]: productObj };
            setOption(updatedObj);
        }
    };

    const handleInputChangeRegions = (value, index, products, accessor) => {
        const productObj = JSON.parse(JSON.stringify(products));
        productObj[index] = { ...productObj[index], regions: value };
        const updatedObj = { ...options, [accessor]: productObj };
        setOption(updatedObj);
    };

    const onRemoveRow = (index, products, accessor) => {
        const productObj = JSON.parse(JSON.stringify(products));
        productObj.splice(index, 1);
        const updatedObj = { ...options, [accessor]: productObj };
        setOption(updatedObj);
    }

    const resolveDisabled = (category) => {
        return typeof category?.accessorDep === 'function'
            ? category?.accessorDep(options)
            : category?.accessorDep && options[category?.accessorDep] !== 'undefined' ? !options[category?.accessorDep] : false;
    }

    const generateDisplayValue = (category, key) => {
        switch (category?.input?.toLowerCase()) {
            case 'checkbox':
                return generateDefaultCheckbox(category, key);
            case 'radio':
                return generateDefaultRadioButton(category, key);
            case 'multilinetext':
                return generateMultiLineTextField(category, key)
            case 'product':
                return generateProductField(category, key);
            case 'dropdown':
                return generateDropDownSingleValue(category, key);
            case 'dropdown-virtualized':
                return generateDropDownVirtualizedSingleValue(category, key);
            case 'multiselect':
                return generateDropDownMultipleValue(category, key);
            case 'multiselect-virtualized':
                return generateDropDownVirtualizedMultipleValue(category, key);
            case 'productlines':
                return generateProductLines(category, key);
            case 'readonly':
                return generateDefaultReadOnlyField(category, key);
            case 'startdate':
                return generateStartDateField(category, key);
            case 'text':
            default:
                return generateDefaultTextField(category, key);
        }
    }

    const generateProductField = (category, key) => {
        const products = options[category?.accessor] ? options[category?.accessor] : [];
        return (
            <Grid item xs={12} key={key}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center" sx={{ height: ItemHeight }}>
                    <InputLabel category={category} />
                    <Grid item xs={6}>
                        {
                            isDisplay ?
                                <FormTypographyDisplayResult variant="subtitle2" >{`${products.map(x => x.productLineName).join(', ')}`}</FormTypographyDisplayResult>
                                : <GeneralProductField
                                    itemValue={products}
                                    accessor={(category?.accessor)}
                                    onEdit={category.editAction}
                                    onClear={category.clearAction}
                                />
                        }
                    </Grid>
                    <Grid item xs={3}>
                        {showPreviousValues(options, category) ?
                            <FormTypographyDisplayPreviousValue variant="subtitle2">{customerOptions[category?.accessor] !== '' ? 'Previously:' : ''} {`${customerOptions[category?.accessor]}`}</FormTypographyDisplayPreviousValue>
                            : ''}

                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const generateDefaultTextField = (category, key) => {
        const disabled = isDisplay || resolveDisabled(category);
        return (
            <Grid item xs={12} key={key} sx={{ height: `${controlHeight}px`, paddingTop: '0px!important' }}>
                <Grid container spacing={0.5} alignItems="center">
                    <InputLabel category={category} />
                    <Grid item xs={6} sx={GridItemStyle}>
                        <GeneralTextField
                            keyPropertyName={category?.accessor}
                            type={category?.type}
                            disabled={disabled}
                            itemValue={options}
                            setItemValue={setOption}
                            handleError={(p) => !!errors[p]}
                            getHelperText={(p) => errors[p] || ''}
                        />
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {showPreviousValues(options, category) ?
                            <FormTypographyDisplayPreviousValue variant="subtitle2"> {customerOptions[category?.accessor] !== '' ? 'Previously:' : ''} {`${customerOptions[category?.accessor]} ${category?.accessor2 ? reverseLengths(customerOptions[category?.accessor2]) : ''}`}</FormTypographyDisplayPreviousValue>
                            : ''}

                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const generateStartDateField = (category, key) => {
        const disabled = isDisplay || resolveDisabled(category);

        const wrapSetOption = (options) => {
            const startDate = options[category?.accessor];
            const endDate = moment(startDate).add(1,'y');
            options[category?.dateContractEndAccessor] = endDate.format('YYYY-MM-DD');
            setOption(options);
        }

        return (
            <Grid item xs={12} key={key} sx={{ height: `${controlHeight}px`, paddingTop: '0px!important' }}>
                <Grid container spacing={0.5} alignItems="center">
                    <InputLabel category={category} />
                    <Grid item xs={6} sx={GridItemStyle}>
                        <GeneralTextField
                            keyPropertyName={category?.accessor}
                            type={category?.type}
                            disabled={disabled}
                            itemValue={options}
                            setItemValue={wrapSetOption}
                            handleError={(p) => !!errors[p]}
                            getHelperText={(p) => errors[p] || ''}
                        />
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {showPreviousValues(options, category) ?
                            <FormTypographyDisplayPreviousValue variant="subtitle2"> {customerOptions[category?.accessor] !== '' ? 'Previously:' : ''} {`${customerOptions[category?.accessor]} ${category?.accessor2 ? reverseLengths(customerOptions[category?.accessor2]) : ''}`}</FormTypographyDisplayPreviousValue>
                            : ''}

                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const generateDefaultReadOnlyField = (category, key) => {
        return (
            <Grid item xs={12} key={key} sx={{ height: `${controlHeight}px`, paddingTop: '0px!important' }}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center" height='100%'>
                    <InputLabel category={category} />
                    <Grid item xs={6} sx={GridItemStyle}>
                        {
                            <Typography variant="subtitle2" >{`${options[category?.accessor] ? options[category?.accessor] : (category?.defaultValue ?? "")} ${category?.accessor2 ? reverseLengths(options[category?.accessor2]) : ''}`}</Typography>
                        }
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {showPreviousValues(options, category) ?
                            <FormTypographyDisplayPreviousValue variant="subtitle2"> {customerOptions[category?.accessor] !== '' ? 'Previously:' : ''} {`${customerOptions[category?.accessor]} ${category?.accessor2 ? reverseLengths(customerOptions[category?.accessor2]) : ''}`}</FormTypographyDisplayPreviousValue>
                            : ''}

                    </Grid>
                </Grid>
            </Grid>
        )
    }


    function generateMultiLineTextField(category, key) {
        return (
            <Grid item xs={12} key={key}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center" >
                    <Grid item xs={12} sx={{ minHeight: "50px" }}>
                        {
                            isDisplay ?
                                <FormTypographyDisplayResult variant="subtitle2">{options.additionalInfo}</FormTypographyDisplayResult>
                                :
                                <TextField
                                    id="additional-info"
                                    value={options.additionalInfo ? options.additionalInfo : ""}
                                    onChange={handleInputChange}
                                    color="primary"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    inputProps={{
                                        style: {
                                            minheight: '50px',
                                        },
                                    }}
                                />
                        }
                        {
                            showPreviousValues(options, category) ?
                                <FormTypographyDisplayPreviousValue variant="subtitle2"> {customerOptions[category?.accessor] !== '' ? 'Previously:' : ''} {customerOptions.additionalInfo}</FormTypographyDisplayPreviousValue>
                                : ''
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    const generateDefaultCheckbox = (category, key) => {
        const disabled = isDisplay || (category?.accessorDep && options[category?.accessorDep] !== 'undefined' ? !options[category?.accessorDep] : false);
        return (
            <Grid item xs={12} key={key} sx={{ height: `${controlHeight}px` }}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center" height='100%'>
                    <InputLabel
                        category={category}
                        sx={{
                            ...(category.accessorDep && !options[category?.accessorDep]) ? disabledLabelStyle : category.labelStyle,
                            ...(category.subsections && !options[category?.accessor]) ? category.labelStyleDisabled : category.labelStyle,

                        }}
                    />
                    <Grid item xs={6} sx={GridItemStyle}>
                        <GeneralCheckBox
                            keyPropertyName={category?.accessor}
                            disabled={disabled}
                            itemValue={options}
                            setItemValue={category.setItemValue ? category.setItemValue : setOption}
                            subsections={category.subsections}
                            {...category.props}
                        />
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {showPreviousValues(options, category) ?
                            <FormTypographyDisplayPreviousValue variant="subtitle2" >Previously: {`${customerOptions[category?.accessor] ? 'Yes' : 'No'} `}</FormTypographyDisplayPreviousValue>
                            : ''}

                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const generateDefaultRadioButton = (category, key) => {
        const disabled = isDisplay || resolveDisabled(category);
        const defaultOptionValue = category.accessor in defaultOptions ? defaultOptions[category.accessor] : "";

        return (
            <Grid item xs={12} key={key} sx={{ height: `${controlHeight}px` }}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center" height='100%'>
                    <InputLabel category={category} />
                    <Grid item xs={6} sx={GridItemStyle}>
                        <div style={{ display: 'flex', flexWrap: "wrap" }}>
                            <GeneralRadioGroup

                                keyPropertyName={category.accessor}
                                values={category?.value}
                                itemValue={options}
                                setItemValue={setOption}
                                disabled={disabled}
                                defaultValue={defaultOptionValue}
                                {...category.props}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {
                            showPreviousValues(options, category) ?
                                <FormTypographyDisplayPreviousValue variant="subtitle2">Previously: {options[category.accessor] ? category.reverse ? category.reverse(customerOptions[category?.accessor]) : customerOptions[category?.accessor] : ""}</FormTypographyDisplayPreviousValue>
                                : ''
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const generateDropDownSingleValue = (category, key) => {
        return generateDropDown(category, key, false, false);
    }

    const generateDropDownVirtualizedSingleValue = (category, key) => {
        return generateDropDown(category, key, false, true);
    }

    const generateDropDownMultipleValue = (category, key) => {
        return generateDropDown(category, key, true, false);
    }

    const generateDropDownVirtualizedMultipleValue = (category, key) => {
        return generateDropDown(category, key, true, true);
    }

    const generateDropDown = (category, key, multiple = false, virtualized = false) => {

        const autoCompleteOptions = Object.values(category?.value);
        const optionValue = !!category?.reverse ? category.reverse(options[category?.accessor]) : options[category?.accessor];
        const initialValue =
            Array.isArray(optionValue)
                ? optionValue.map(x => category?.value[x])
                : category.value[optionValue]
                ?? (multiple ? [] : '');

        const disabled = isDisplay || resolveDisabled(category);

        return (
            <Grid item xs={12} key={key} sx={{ minHeight: `${controlHeight}px`, paddingTop: '0px!important' }}>
                <Grid container spacing={0.5} justifyContent="center" alignItems="center">
                    <InputLabel category={category} />
                    <Grid item xs={6} sx={GridItemStyle}>
                        {
                            virtualized
                                ? <GeneralVirtualizedAutocomplete
                                    label=''
                                    multiple={multiple}
                                    keyPropertyName={category?.accessor}
                                    options={autoCompleteOptions}
                                    itemValue={options}
                                    setItemValue={setOption}
                                    value={initialValue}
                                    handleError={() => !!category?.error}
                                    getHelperText={() => category?.error}
                                    disabled={disabled}
                                />
                                : <GeneralAutocomplete
                                    label=''
                                    multiple={multiple}
                                    keyPropertyName={category?.accessor}
                                    options={autoCompleteOptions}
                                    itemValue={options}
                                    setItemValue={setOption}
                                    value={initialValue}
                                    handleError={() => !!category?.error}
                                    getHelperText={() => category?.error}
                                    disabled={disabled}
                                />
                        }
                    </Grid>
                    <Grid item xs={3} sx={GridItemStyle}>
                        {
                            showPreviousValues(options, category) ?
                                <FormTypographyDisplayPreviousValue variant="subtitle2">Previously: {options[category.accessor] ? category.reverse ? category.reverse(customerOptions[category?.accessor]) : customerOptions[category?.accessor] : ""}</FormTypographyDisplayPreviousValue>
                                : ''
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const generateProductLines = (category) => {
        const products = options[category?.accessor] ? options[category?.accessor] : [];
        const refProducts = customerOptions[category?.accessor];
        const additionalColumns = category?.additionalColumns ?? [];
        return (
            <Grid container justifyContent="center" alignItems="center" paddingBottom={isDisplay ? 3 : 8} minHeight={500} sx={GridItemStyle}>
                <Grid item xs={12} height='100%' sx={GridItemStyle}>
                    <Paper sx={{ height: '100%' }} elevation={0}>
                        {isDisplay ? "" :
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            >
                                <div>
                                    <Typography style={{ color: 'red' }}>{errors[category?.accessor]}</Typography>
                                    <Typography>Please click on a cell to edit its value</Typography>
                                </div>
                                <ToolbarProductList itemValue={products}
                                    accessor={(category?.accessor)}
                                    onEdit={category.editAction}
                                    onClear={category.clearAction}
                                    title={category?.title} />
                            </div>
                        }
                        <ProductLineTable
                            products={products}
                            refProducts={refProducts}
                            isDisplay={isDisplay}
                            handleRegion={(value, index) => handleInputChangeRegions(value, index, products, category?.accessor)}
                            handleYearFrom={(value, index) => handleInputChangeYear(value, index, products, ColumnYearFrom, category?.accessor)}
                            handleYearTo={(value, index) => handleInputChangeYear(value, index, products, ColumnYearTo, category?.accessor)}
                            onRemoveRow={(index) => onRemoveRow(index, products, category?.accessor)}
                            additionalColumns={additionalColumns}
                        />
                    </Paper>
                </Grid>
            </Grid >
        )
    }

    return (
        <Grid container justifyContent="center" sx={{ width: "100%", ml: 0 }} key={id}>
            {data.map((categories, index) => {
                return (
                    <Grid item xs={categories.gridSize && categories.gridSize > 0 ? categories.gridSize : 6} key={id + "-" + index} sx={{ border: "1px solid", borderRight: 0 }} >
                        <Grid container p={0}>
                            <Grid item xs={12} sx={{ textAlign: "center", borderBottom: "1px solid" }}>
                                <FormTypographyDisplayResult variant="subtitle2" sx={{ background: "#7d8492", color: "#fff" }}>{categories.headerTitle}</FormTypographyDisplayResult>
                            </Grid>
                        </Grid>
                        <Grid container p={1} key={id + "-section-" + index} sx={{ padding: 2 }}>
                            {categories.sections.map((category, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {generateDisplayValue(category, category.title + "-" + key)}
                                        {category.subsections ? category.subsections.map((subsectionCategory, subsectionKey) => {
                                            return generateDisplayValue(subsectionCategory, subsectionCategory.title + "-" + subsectionKey)
                                        }) : ''
                                        }
                                    </React.Fragment>

                                )
                            })}
                        </Grid>
                    </Grid>

                )
            })}
        </Grid>
    )
}

const InputLabel = ({ category, ...rest }) => {
    return (
        <Grid item xs={3} sx={GridItemStyle}>
            <FormTypographyLabel variant="subtitle2" sx={{ ...(category.labelStyle ?? {}), ...(rest?.sx ?? {}) }}>{category.title}</FormTypographyLabel>
        </Grid>
    );
}

export default FormTemplate
