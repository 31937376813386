import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';



const ActionBar = ({actionInfos, setActionInfos}) => {

    const handleOnClick = name => setActionInfos({...actionInfos, actionClicked: name});

    return (
        <React.Fragment>
            <Grid container sx={{minHeight: '48px', paddingBottom: '8px', justifyContent: "space-between"}}>
                <Grid item container justifyContent="flex-end">
                {
                    actionInfos.actions.map(({label, name, disabled = false}, i) => 
                    <Grid item key={i} sx={{paddingLeft: '8px'}}>
                    {    
                        <Button 
                            variant="contained"
                            sx={!disabled? {backgroundColor:'#ff562b!important',color:'white!important'}: {}}
                            onClick={() => handleOnClick(name)}
                            disabled={disabled}
                        >
                            {label}
                        </Button>
                    }
                    </Grid>)
                }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ActionBar;