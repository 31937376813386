import React from 'react';

import Box from '@mui/material/Box';
import { Lengths, Hardware, Hosting, DBTypes, PartCatConfig } from '../../constants/DefaultOptions';
import { reverseHardware, reverseHosting, reverseDBTypes } from '../../utils';
import FormTemplate from './FormTemplate';

const PartCatOptions = ({ options, setOption, errors, isDisplay, showCustomerOptions, customerOptions, defaultOptions }) => {

    const setAutoCareSubscription = (newOptions) => {
        if (!newOptions.autoCareSubscription) {
            newOptions.vcdb = false;
            newOptions.vcdbType = '';
            newOptions.padb = false;
        }
        setOption(newOptions);
    };

    const PartCatForm = [
        {
            headerTitle: PartCatConfig.headerTitles.partcatOption,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.active.title,
                    accessor: PartCatConfig.active.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.contractLength.title,
                    accessor:  PartCatConfig.contractLength.accessor,
                    accessor2:  PartCatConfig.contractLength.accessor2,
                    type: "number",
                    input: "text",

                },
                {
                    title:  PartCatConfig.lengthType.title,
                    accessor:  PartCatConfig.lengthType.accessor,
                    input: "radio",
                    value: Lengths,

                },
                {
                    title: PartCatConfig.startDate.title,
                    accessor: PartCatConfig.startDate.accessor,
                    type: "date",
                    input: "text",
                },
                {
                    title:PartCatConfig.licenseLength.title,
                    accessor: PartCatConfig.licenseLength.accessor,
                    accessor2: PartCatConfig.licenseLength.accessor2,
                    type: "number",
                    input: "text",
                },
                {
                    title: PartCatConfig.licenseType.title,
                    accessor: PartCatConfig.licenseType.accessor,
                    input: "radio",
                    value: Lengths,

                },
            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.autocare,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.autoCareSubscription.title,
                    accessor: PartCatConfig.autoCareSubscription.accessor,
                    input: "checkbox",
                    setItemValue: setAutoCareSubscription,
                },
                {
                    title: PartCatConfig.vcdb.title,
                    accessor: PartCatConfig.vcdb.accessor,
                    input: "checkbox",
                    accessorDep:  PartCatConfig.vcdb.accessorDep,
                },
                {
                    title: PartCatConfig.vcdbType.title,
                    accessor: PartCatConfig.vcdbType.accessor,
                    input: "text",
                    accessorDep:  PartCatConfig.vcdbType.accessorDep,
                },
                {
                    title: PartCatConfig.padb.title,
                    accessor: PartCatConfig.padb.accessor,
                    input: "checkbox",
                    accessorDep: PartCatConfig.padb.accessorDep,
                },
            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.software,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.publishing.title,
                    accessor: PartCatConfig.publishing.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.customReport.title,
                    accessor: PartCatConfig.customReport.accessor,
                    input: "checkbox",
                },

                {
                    title: PartCatConfig.webOutput.title,
                    accessor: PartCatConfig.webOutput.accessor,
                    input: "checkbox",
                },


            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.installation,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.hardware.title,
                    accessor: PartCatConfig.hardware.accessor,
                    input: "radio",
                    value: Hardware,
                    reverse: reverseHardware,
                    props: {
                        containerStyle: { minWidth: "96px" },
                    }
                },
                {
                    title:PartCatConfig.hardwareHosting.title,
                    accessor: PartCatConfig.hardwareHosting.accessor,
                    input: "radio",
                    value: Hosting,
                    reverse: reverseHosting,
                    props: {
                        containerStyle: { minWidth: "96px" },
                    }
                },
                {
                    title: PartCatConfig.multiUser.title,
                    accessor: PartCatConfig.multiUser.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.windowsCertified.title,
                    accessor: PartCatConfig.windowsCertified.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.activeFirewall.title,
                    accessor: PartCatConfig.activeFirewall.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.microsoftTeam.title,
                    accessor: PartCatConfig.microsoftTeam.accessor,
                    input: "checkbox",
                },

            ]
        },

        {
            headerTitle: PartCatConfig.headerTitles.database,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.dbType.title,
                    accessor: PartCatConfig.dbType.accessor,
                    input: "radio",
                    value: DBTypes,
                    reverse: reverseDBTypes,
                },
                {
                    title: PartCatConfig.dbVersion.title,
                    accessor: PartCatConfig.dbVersion.accessor,
                    input: "text",
                },

            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.subscriptions,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.oeData.title,
                    accessor: PartCatConfig.oeData.accessor,
                    input: "checkbox",

                },
                {
                    title: PartCatConfig.vioData.title,
                    accessor: PartCatConfig.vioData.accessor,
                    input: "checkbox",
                },

            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.napa,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.acesToNapa.title,
                    accessor: PartCatConfig.acesToNapa.accessor,
                    input: "checkbox",

                },
                {
                    title: PartCatConfig.napaConversion.title,
                    accessor: PartCatConfig.napaConversion.accessor,
                    input: "checkbox",
                },

            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.tecdoc,
            defaultExpanded: true,
            sections: [
                {
                    title: PartCatConfig.acesToTecDoc.title,
                    accessor: PartCatConfig.acesToTecDoc.accessor,
                    input: "checkbox",

                },
                {
                    title: PartCatConfig.tecdocConversion.title,
                    accessor: PartCatConfig.tecdocConversion.accessor,
                    input: "checkbox",
                },
                {
                    title: PartCatConfig.deliveryDate.title,
                    accessor: PartCatConfig.deliveryDate.accessor,
                    type: "date",
                    input: "text",
                },
            ]
        },
        {
            headerTitle: PartCatConfig.headerTitles.additionalInfo,
            gridSize: 12,
            sections: [
                {
                    title: PartCatConfig.additionalInfo.title,
                    accessor: PartCatConfig.additionalInfo.accessor,
                    input: "multilinetext",
                }
            ]
        },
    ]


    return (
        <Box>
            <FormTemplate
                data={PartCatForm}
                id="PartCat"
                options={options}
                errors={errors}
                isDisplay={isDisplay}
                setOption={setOption}
                showCustomerOptions={showCustomerOptions}
                customerOptions={customerOptions}
                defaultOptions={defaultOptions}
            />
        </Box>
    )
}

export default PartCatOptions;