import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import GeneralTextField from "../GeneralTextField";
import GeneralCheckBox from "../GeneralCheckBox";
import AddEditContact from './AddEditContact';
import { createAccessorStatus, HandleContactsActionsCell, createContactAccessor } from '../../utils';
import '../../styles/toolbar.css';


const GeneralOptions = ({ options,companyStatus, setOption, errors, isDisplay, showCustomerOptions, customerOptions }) => {

    const [status, setStatus] = useState({ open: false, contactToEdit: {}, index: -1 });
    const [data, setData] = useState({contacts: [], addIds: [], modContacts: {}, delIds: []});

    useEffect(() => {
        var displayContacts = [...options.contacts];
        var addIds = [];
        var modContacts = {};
        var delIds = [];
        if (showCustomerOptions) {
            options.contacts.forEach(c => {
                const res = customerOptions.contacts.filter(cc => c.id === cc.id);
                if (res?.length > 0) {
                    modContacts[c.id] = res[0];
                } else {
                    addIds.push(c.id);
                }
            });
            const ids = options.contacts.map(c => c.id);
            customerOptions.contacts.forEach(cc => {
                if (!ids.includes(cc.id)) {
                    delIds.push(cc.id);
                    displayContacts.push(cc);
                }
            });
        }
        setData({contacts: displayContacts.sort((a, b) => b.name.localeCompare(a.name)), addIds: addIds, modContacts: modContacts, delIds: delIds})
    }, [options.contacts, showCustomerOptions, customerOptions.contacts]);

    const updateContacts = (newContacts) => {
        setOption({...options, contacts: newContacts.filter(c => !data.delIds.includes(c.id)).sort((a, b) => b.name.localeCompare(a.name))});
    }

    const actionAccessor = (row, index) => {
        return index;
    }

    const customSortType = (a, b, accessor) => {
        return a.original[accessor].localeCompare(b.original[accessor]);
    }

    const createFilter = (fieldkey) => (rows, fieldName, fragment) => {
        return rows.filter((r) => r.original[fieldkey] && r.original[fieldkey].toUpperCase().includes(fragment.toUpperCase()));
    }

    const columnDefs = [
        ... !isDisplay ? [{
            id: 1,
            Header: "Actions",
            width: 40,
            Filter: <Box sx={{height:"28px"}}></Box>,
            accessor: actionAccessor,
            Cell: props => HandleContactsActionsCell(props,data.contacts,data.delIds,updateContacts,setStatus),
        }] : [],
        {
            Header: 'Name',
            accessor: (row) => createContactAccessor(row, data, 'name'),
            sortType: (a, b) => customSortType(a, b, 'name'),
            filter: createFilter('name')
        },
        {
            Header: 'Type',
            accessor: (row) => createContactAccessor(row, data, 'type'),
            sortType: (a, b) => customSortType(a, b, 'type'),
            filter: createFilter('type')
        },
        {
            Header: 'Email',
            accessor: (row) => createContactAccessor(row, data, 'email'),
            sortType: (a, b) => customSortType(a, b, 'email'),
            filter: createFilter('email')
        },
        {
            Header: 'Phone #',
            accessor: (row) => createContactAccessor(row, data, 'phone'),
            sortType: (a, b) => customSortType(a, b, 'phone'),
            filter: createFilter('phone')
        }
    ];

    const setSelectedRows = (selectedFlatRows) => { };

    const addContacts = () => {
        setStatus({ open: true, contactToEdit: {}, index: -1 });
    }

    return (
        <Box sx={{height:`100%`}}>
            <Grid container sx={{height:"100%"}}>
                <Grid item xs={12}>
                <Grid container sx={{px:2, pt: 2}}>
                        <Grid item xs={4}>
                            <Typography variant="h6"><b>Company Name</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6"><b>Company Address</b></Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Typography variant="h6"><b>Status</b></Typography>
                        </Grid>
                        <Grid item xs={2} ></Grid>
                    </Grid>
                    <Grid container sx={{px:2, pb: 2, alignItems: 'center'}}>
                        <Grid item xs={4}>
                            {
                                isDisplay ?
                                    <Typography variant="h6">{options.companyName}</Typography>
                                    :
                                    <GeneralTextField
                                        keyPropertyName="companyName"
                                        sx={{maxWidth: '400px'}}
                                        itemValue={options}
                                        setItemValue={setOption}
                                        handleError={(p) => !!errors[p]}
                                        getHelperText={(p) => errors[p] || ''}
                                    />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {
                                isDisplay ?
                                    <Typography variant="h6">{options.companyAddress}</Typography>
                                    :
                                    <GeneralTextField
                                        keyPropertyName="companyAddress"
                                        sx={{maxWidth: '400px'}}
                                        itemValue={options}
                                        setItemValue={setOption}
                                        handleError={(p) => !!errors[p]}
                                        getHelperText={(p) => errors[p] || ''}
                                    />
                            }
                        </Grid>
                        <Grid item xs={2} >
                            {options.companyName.length > 0 ? createAccessorStatus({status:companyStatus, options:{isCustomerHidden: options?.isCustomerHidden}}):''}
                        </Grid>
                        <Grid item xs={2} display='flex' alignItems='center'>
                            {
                                !isDisplay &&
                                <div>
                                    <GeneralCheckBox
                                        label="Hide Customer"
                                        keyPropertyName="isCustomerHidden"
                                        itemValue={options}
                                        setItemValue={setOption}
                                    />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ height: "100%",minHeight:"180px" }}>
                    <DivTableStyles style={{height:`calc(100% - 80px)`,minHeight:"112px"}}>
                        <JnpDivTable
                            columns={columnDefs}
                            data={data.contacts}
                            selectable={false}
                            disableSelect={true}
                            setSelectedRows={setSelectedRows}
                            defaultSortColumn="name"
                            title="Contacts"
                            backGroundColor="#003061"
                            sx={{padding:0}}
                        />
                    </DivTableStyles>
                </Grid>
                <Grid item xs={12} sx={{ margin: '16px 10px 0px 0px', textAlign: 'end' }}>
                    {
                        isDisplay ? '' :
                            <Button variant="contained"  sx={{backgroundColor:'#ff562b',color:'white'}}  onClick={addContacts}>
                                Add Contact
                            </Button>
                    }
                </Grid>

            </Grid>
            <AddEditContact options={options} updateContacts={updateContacts} status={status} setStatus={setStatus} customerOptions={customerOptions} />
        </Box>
    );
}

export default GeneralOptions;