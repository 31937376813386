import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const GeneralProductField = (props) => {

    const {
        itemValue,
        accessor,
        onEdit,
        onClear,
        containerStyle = {}
    } = props;

    const FormTypographyDisplayResult = styled(Typography)`
        padding-left:9px;
    `;

    const handleEdit = () => {
        onEdit(accessor);
    }

    const handleClear = () => {
        onClear(accessor);
    }

    return (
        <Box sx={{ marginLeft: "9px", ...containerStyle }}>
            <Grid container spacing={0.5} justifyContent="center" alignItems="center" sx={{ height: "48px" }}>
                <Grid item xs={6}>
                    <FormTypographyDisplayResult variant="subtitle2" >
                        {itemValue.map(x => x.productLineName).join(', ')}
                    </FormTypographyDisplayResult>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={handleEdit}>Select</Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={handleClear}>clear</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default GeneralProductField;