import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ConfirmationDialog = ({open, setOpen, ...remainingProps}) => {
    const {
        id,
        textTitle,
        textContent,
        confirmButtonLabel,
        cancelButtonLabel,
        executeAction = (done) => { done() },
    } = remainingProps;

    const handleClose = () => {
        setOpen(false);
    };

    const handleAction = () => {
        executeAction();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby={`${id}-dialog-title`}
            aria-describedby={`${id}-dialog-description`}
        >
            <DialogTitle id={`${id}-dialog-title`}>{textTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id={`${id}-dialog-description`}>
                    {textContent}
                </DialogContentText>
                { remainingProps.children }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {cancelButtonLabel}
                </Button>
                <Button onClick={handleAction} color="primary">
                    {confirmButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;