
const Status = {
    INCOMPLETE: 1,
    PENDING_CREATION_APPROVAL: 2,
    ACTIVE: 3,
    CHANGE_REQUESTED: 4,
    PENDING_UPDATE_APPROVAL: 5,
    PENDING_TERMINATION_APPROVAL: 6,
};

const Lengths = {
    Years: { label: "Years", value: 1 },
    Months: { label: "Months", value: 2 },
    Weeks: { label: "Weeks", value: 3 },
};

const Hardware = {
    Server: { label: "Server", value: 1 },
    SinglePC: { label: "Single PC", value: 2 }
};

const Hosting = {
    Premise: { label: "Hosting", value: 1 },
    Cloud: { label: "Cloud", value: 2 },
};

const DBTypes = {
    None: { label: "None", value: 0 },
    MySQL: { label: "MySQL", value: 1 },
    MSSQL: { label: "Microsoft SQL", value: 2 },
};

const ResearchType = {
    Post95: { label: "95+", value: 1 },
    Pre95: { label: "Pre 95", value: 2 },
}

const DeliveryType = {
    Xml: { label: "XML", value: 1 },
    Excel: { label: "Excel", value: 2 },
}

const UpdateFrequency = {
    Monthly: { label: "Monthly", value: 1 },
    Quarterly: { label: "Quarterly", value: 2 },
    Yearly: { label: "Yearly", value: 3 },
}

const OeDataSource = {
    JNP: { label: "JNP", value: 1 },
    Motor: { label: "Motor", value: 2 },
}

const VioDataRegion = {
    USA: { label: "USA", value: 1 },
    CAN: { label: "CAN", value: 2 },
    MEX: { label: "MEX", value: 3 }
}

const VioDataLevel = {
    National: { label: "National", value: 1 },
    State: { label: "State", value: 2 }
}

const ContractFrequency = {
    Yearly: { label: "Yearly", value: 1 },
    Quarterly: { label: "Quarterly", value: 2 }
}

const OeFrequency = {
    OneTime: { label: "Once", value: 1 },
    Weekly: { label: "Weekly", value: 2 },
    BiWeekly: { label: "Biweekly", value: 3 },
    Monthly: { label: "Monthly", value: 4 },
    Quarterly: { label: "Quarterly", value: 5 },
    Yearly: { label: "Yearly", value: 6 },
}

const PCOVCDBTypes = {
    LD: { label: "LD", value: "LD" },
    MDHD: { label: "MDHD", value: "MDHD" },
    PS: { label: "PS", value: "PS" },
}

const OEFormats = {
    "Aces Cross Ref": { label: "Aces Cross Ref", value: "Aces Cross Ref" },
    "Excel": { label: "Excel", value: "Excel" },
    "KType": { label: "KType", value: "KType" },
    "SQL": { label: "SQL", value: "SQL" },
    "XML": { label: "XML", value: "XML" },
    "VIO": { label: "VIO", value: "VIO" },
}

const OnboardingRequired = {
    Yes: { label: 'Yes', value: 1 },
    Basic: { label: 'Basic', value: 2 },
    No: { label: 'No', value: 3 },
}

export const OECustomerType = {
    Full: { label: 'Full', value: 1 },
    'On Demand': { label: 'On Demand', value: 2 },
}

const DefaultOptions = {
    companyName: '',
    companyAddress: '',
    contacts: [],
    isCustomerHidden: false,
    partcat_options: {
        active: false,
        contractLength: 0,
        lengthType: Lengths.Years.value,
        startDate: '',
        licenseLength: 0,
        licenseType: Lengths.Years.value,
        autoCareSubscription: false,
        vcdb: false,
        vcdbType: '',
        padb: false,
        publishing: false,
        customReport: false,
        weboutput: false,
        hardware: Hardware.Server.value,
        hardwareHosting: Hosting.Premise.value,
        multiUser: false,
        windowsCertified: false,
        activeFirewall: false,
        microsoftTeam: false,
        dbType: DBTypes.MySQL.value,
        dbVersion: '',
        oeData: false,
        vioData: false,
        acesToNapa: false,
        napaConversion: false,
        acesToTecDoc: false,
        tecdocConversion: false,
        deliveryDate: '',
        additionalInfo: '',
    },
    dm_options: {
        active: false,
        contractLength: 0,
        lengthType: Lengths.Years.value,
        startDate: '',
        endDate: '',
        existingAces: false,
        acesDeliveryType: DeliveryType.Xml.value,
        buildDataFromOe: false,
        numberOfOePart: 0,
        researchType: ResearchType.Post95.value,
        crossReferenceExisting: false,
        heavyDuty: false,
        customData: false,
        piesExistingPies: false,
        piesDeliveryType: DeliveryType.Xml.value,
        piesRebranding: false,
        padbExistingPies: false,
        padbDeliveryType: DeliveryType.Xml.value,
        physicalImages: false,
        piesDigitalAssetImages: false,
        physical360Images: false,
        piesDigitalAsset360Images: false,
        updateFrequency: UpdateFrequency.Monthly.value,
        partUpdateQuota: '',
        tecdocActive: false,
        tecdocOeCrossReference: false,
        tecdocApplicationData: false,
        tecdocPiesData: false,
        tecdocPadbData: false,
        tecdocDigitalAssetData: false,
        oeDataActive: false,
        oeDataSource: OeDataSource.JNP.value,
        oeDataProductLines: [],
        oeDataCarryOver: false,
        oeDataShoppingList: false,
        oeCustomerType: OECustomerType.Full.value,
        vioDataActive: false,
        vioDataRegion: VioDataRegion.USA.value,
        vioDataLevel: VioDataLevel.National.value,
        napaDataActive: false,
        napaDataConversion: false,
        opticatSubscription: false,
        objectiveShortTerm: '',
        objectiveMidTerm: '',
        objectiveLongTerm: '',
        objectivePromiseToCustomer: '',
        objectiveTimeline: '',
    },
    vio_options: {
        active: false,
        contractLength: 0,
        sameAsMainContact: false,
        name: '',
        email: '',
        phone: '',
        contractDate: '',
        contractEndDate: '',
        contractFrequency: ContractFrequency.Yearly.value,
        contractActiveForDelivery: false,

        usaLightDutyActive: false,
        usaLightDutyNational: false,
        usaLightDutyState: false,
        usaLightDutyCounty: false,
        usaLightDutyZip: false,

        usaHeavyDutyActive: false,
        usaHeavyDutyNational: false,
        usaHeavyDutyState: false,
        usaHeavyDutyCounty: false,
        usaHeavyDutyZip: false,

        canLightDutyActive: false,
        canLightDutyNational: false,
        canLightDutyProvince: false,
        canLightDutyMunicipal: false,
        canLightDutyPostalCode: false,

        canHeavyDutyActive: false,
        canHeavyDutyNational: false,
        canHeavyDutyProvince: false,
        canHeavyDutyMunicipal: false,
        canHeavyDutyPostalCode: false,

        mexLightDutyActive: false,
        mexLightDutyState: false,
        mexLightDutyNational: false,
        mexLightDutyMunicipal: false,

        mexHeavyDutyActive: false,
        mexHeavyDutyState: false,
        mexHeavyDutyNational: false,
        mexHeavyDutyMunicipal: false,
        additionalInfo: '',
    },
    oe_options: {
        active: false,
        contractLength: 0,
        frequency: UpdateFrequency.Monthly.value,
        partCatVersion: '',
        ftpUsername: '',
        productLines: [],
        dbType: DBTypes.None.value,
        dbVersion: '',
        formats: [],
        dataSentToDMOnly: false,
        additionalInfo: '',
    },
    pco_options: {
        active: false,
        contractLength : 0,
        vcdbTypes: [],
        hasPADB: false,
        hasQDB: false,
        customerId: null,
        adminAccount : '',
        additionalAccounts: '',
        brandAAIA: '',
        expectedNumberOfParts: 0,
        hasACES: false,
        onboardingRequired: OnboardingRequired.Yes.value,
        hasACESXML: false,
        hasACESSpreadsheet : false,
        hasPIESXML: false,
        hasPIESSpreadsheet: false,
        digitalAssets: false,
        conversionNeeded : false,
        additionalInfo: '',
    }
};

const requestStatus = {
    INCOMPLETE: 1,
    PENDING_APPROVAL: 2,
    CHANGE_REQUESTED: 3,
    APPROVED: 4,
    COMPLETED: 5,
    ABANDONED: 6,
};

const requestStatusDescription = [
    '',
    'INCOMPLETE',
    'PENDING CREATION APPROVAL',
    'ACTIVE',
    'CHANGE REQUESTED',
    'PENDING UPDATE APPROVAL',
    'PENDING TERMINATION APPROVAL'
];

const shortRequestStatusDescription = [
    '',
    'Incomplete',
    'Pending Approval',
    'Change Requested',
    'Approved',
    'Completed',
    'Abandoned'
];

const requestTypes = {
    CUSTOMER_UPDATE: 1,
    CUSTOMER_TERMINATION: 2,
    CUSTOMER_DELIVERY: 3,
};

const requestTypesDescription = [
    '',
    'Customer Update',
    'Customer Termination',
    'Customer Delivery'
];

const DefaultRequest = {
    customerID: 0,
    type: requestTypes.CUSTOMER_UPDATE,
    status: requestStatus.PENDING_APPROVAL,
    options: DefaultOptions,
    lastUpdateUser: '',
};

const DefaultCustomer = {
    type: 0,
    status: Status.INCOMPLETE,
    options: DefaultOptions,
    lastUpdateUser: '',
    currentRequest: DefaultRequest,
    requests: [],
    showCustomerOptions: false,
    hasActiveRequest: false,
};

const Types = {
    PartCat: 1,
    DM: 2,
    VIO: 4,
    OE: 8,
    PCO: 16,
};

const DefaultTitle = "New Customer";

const Regions = [{ value: 'MEX', label: 'MEX' }, { value: 'US-CAN', label: 'US/CAN' }];

const Availabilities = [{ value: '6-weeks', label: '6 Weeks' }, { value: 'Now', label: 'Now' }];

const OeCategories = [{ value: 'A', label: 'A' }, { value: 'B', label: 'B' }, { value: 'C', label: 'C' }, { value: 'D', label: 'D' }];

const defaultProduct = {
    productLineId: -1,
    productLineName: '',
    partTerminologies: [],
    regions: '',
    availability: '',
    oeCategories: '',
    yearFrom: 0,
    yearTo: 0,
    lastUpdateUser: 'User',
}

const DMConfig = {
    headerTitles: {
        dataManagement: "Data Management options",
        application: "Application Data",
        pies: "PIES Data",
        padb: "PADB Data",
        digital: "Digital Asset",
        maintenance: "Maintenance Information",
        tecdoc: "TecDoc Data Pull",
        oe: "OE Data",
        oeProducts: "OE Data Product Lines",
        carryoverProducts: "Carry Over Product Lines",
        shoppingProducts: "Shopping List Product Lines",
        vio: "VIO Data",
        napa: "NAPA Data",
        opticat: "Opticat Subscription",
        objective: "Client Objectives",
    },
    "active": {
        title: "Active",
        accessor: "active",
        input: "checkbox",
    },
    "contractLength": {
        title: "Contract Length",
        accessor: "contractLength",
    },
    "lengthType": {
        title: "Length Type",
        accessor: "lengthType",
    },
    "startDate": {
        title: "Start Date",
        accessor: "startDate",
    },
    "endDate": {
        title: "End Date",
        accessor: "endDate",
    },
    "existingAces": {
        title: "Existing ACES application data",
        accessor: "existingAces",
    },
    "acesDeliveryType": {
        title: "Delivery Type",
        accessor: "acesDeliveryType",
    },
    "buildDataFromOe": {
        title: "Build Data from OE",
        accessor: "buildDataFromOe",
    },
    "numberOfOePart": {
        title: "Number of OE Part",
        accessor: "numberOfOePart",
    },
    "researchType": {
        title: "Research Type",
        accessor: "researchType",
    },
    "crossReferenceExisting": {
        title: "Cross Reference Existing",
        accessor: "crossReferenceExisting",
    },
    "heavyDuty": {
        title: "Heavy Duty",
        accessor: "heavyDuty",
    },
    "customData": {
        title: "Custom Data",
        accessor: "customData",
    },
    "piesExistingPies": {
        title: "Existing PIES Data",
        accessor: "piesExistingPies",
    },
    "piesDeliveryType": {
        title: "Delivery Type",
        accessor: "piesDeliveryType",
    },
    "piesRebranding": {
        title: "Pies Rebranding",
        accessor: "piesRebranding",
    },
    "padbExistingPies": {
        title: "Existing PIES Data",
        accessor: "padbExistingPies",
    },
    "padbDeliveryType": {
        title: "Delivery Type",
        accessor: "padbDeliveryType",
    },
    "physicalImages": {
        title: "Physical Images",
        accessor: "physicalImages",
    },
    "piesDigitalAssetImages": {
        title: "Pies Digital Asset For Images",
        accessor: "piesDigitalAssetImages",
    },
    "physical360Images": {
        title: "Physical 360 Images",
        accessor: "physical360Images",
    },
    "piesDigitalAsset360Images": {
        title: "Pies Digital Asset for 360 Images",
        accessor: "piesDigitalAsset360Images",
    },
    "updateFrequency": {
        title: "Update Frequency",
        accessor: "updateFrequency",
    },
    "partUpdateQuota": {
        title: "Part Update Quota",
        accessor: "partUpdateQuota",
    },
    "tecdocActive": {
        title: "Active",
        accessor: "tecdocActive",
    },
    "tecdocOeCrossReference": {
        title: "OE Cross Reference",
        accessor: "tecdocOeCrossReference",
    },
    "tecdocApplicationData": {
        title: "Application Data",
        accessor: "tecdocApplicationData",
    },
    "tecdocPiesData": {
        title: "PIES Data",
        accessor: "tecdocPiesData",
    },
    "tecdocPadbData": {
        title: "PADB Data",
        accessor: "tecdocPadbData",
    },
    "tecdocDigitalAssetData": {
        title: "Digital Asset Data",
        accessor: "tecdocDigitalAssetData",
    },
    "oeDataActive": {
        title: "Active",
        accessor: "oeDataActive",
    },
    'oeCustomerType': {
        title: 'Oe Customer Type',
        accessor: 'oeCustomerType'
    },
    "oeDataSource": {
        title: "OE Source",
        accessor: "oeDataSource",
    },
    "oeDataCarryOver": {
        title: "Carry Over",
        accessor: "oeDataCarryOver",
    },
    "oeDataShoppingList": {
        title: "Shopping List",
        accessor: "oeDataShoppingList",
    },
    "oeDataProductLines": {
        title: "OE Data Product Lines",
        accessor: "oeDataProductLines",
    },
    "vioDataActive": {
        title: "Active",
        accessor: "vioDataActive",
    },
    "vioDataRegion": {
        title: "VIO Region",
        accessor: "vioDataRegion",
    },
    "vioDataLevel": {
        title: "VIO Level",
        accessor: "vioDataLevel",
    },
    "napaDataActive": {
        title: "Active",
        accessor: "napaDataActive",
    },
    "napaDataConversion": {
        title: "NAPA Conversion",
        accessor: "napaDataConversion",
    },
    "opticatSubscription": {
        title: "Active",
        accessor: "opticatSubscription",
    },
    "objectiveShortTerm": {
        title: "Short Term",
        accessor: "objectiveShortTerm",
    },
    "objectiveMidTerm": {
        title: "Mid Term",
        accessor: "objectiveMidTerm",
    },
    "objectiveLongTerm": {
        title: "Long Term",
        accessor: "objectiveLongTerm",

    },
    "objectivePromiseToCustomer": {
        title: "What was Promised to Customer",
        accessor: "objectivePromiseToCustomer",
    },
    "objectiveTimeline": {
        title: "Time Line Established",
        accessor: "objectiveTimeline",
    },
}

const PartCatConfig = {
    headerTitles: {
        partcatOption: "PartCat Options",
        autocare: "Auto Care",
        software: "PartCat Software Features",
        installation: "Installation Information",
        database: "Database",
        subscriptions: "Additional Subscriptions",
        napa: "NAPA Standard",
        tecdoc: "TecDoc Standard",
        additionalInfo: "Additional Information",
    },
    "active": {
        title: "Active",
        accessor: "active",
        input: "checkbox",
    },
    "contractLength": {
        title: "Contract Length",
        accessor: "contractLength",
        accessor2: "lengthType",
    },
    "lengthType": {
        title: "Length",
        accessor: "lengthType",
    },
    "startDate": {
        title: "Start Date",
        accessor: "startDate",
    },
    "licenseLength": {
        title: "License Length",
        accessor: "licenseLength",
        accessor2: "licenseType",
    },
    "licenseType":
    {
        title: "License Type",
        accessor: "licenseType",
    },
    "autoCareSubscription": {
        title: "Subscription",
        accessor: "autoCareSubscription",
    },
    "vcdb": {
        title: "VCDB",
        accessor: "vcdb",
        accessorDep: "autoCareSubscription",
    },
    "vcdbType": {
        title: "VCDB Type",
        accessor: "vcdbType",

        accessorDep: "autoCareSubscription",
    },
    "padb":
    {
        title: "PADB",
        accessor: "padb",
        accessorDep: "autoCareSubscription",
    },
    "publishing": {
        title: "Publishing Module (PDF Print)",
        accessor: "publishing",
    },
    "customReport": {
        title: "Custom Report",
        accessor: "customReport",
    },
    "webOutput": {
        title: "Web Output (MyPartFinder)",
        accessor: "webOutput",
    },
    "hardware": {
        title: "Hardware",
        accessor: "hardware",
    },
    "hardwareHosting": {
        title: "Hardware Hosting",
        accessor: "hardwareHosting",
    },
    "multiUser": {
        title: "Multi-User setup",
        accessor: "multiUser",
    },
    "windowsCertified": {
        title: "Windows 10 certified",
        accessor: "windowsCertified",
    },
    "activeFirewall": {
        title: "Active Firewall",
        accessor: "activeFirewall",
    },
    "microsoftTeam": {
        title: "Microsoft Teams",
        accessor: "microsoftTeam",
    },
    "dbType": {
        title: "Type",
        accessor: "dbType"
    },
    "dbVersion": {
        title: "Version",
        accessor: "dbVersion",
    },
    "oeData": {
        title: "OE Data",
        accessor: "oeData",
    },
    "vioData": {
        title: "VIO Data",
        accessor: "vioData",
    },
    "acesToNapa": {
        title: "ACES To NAPA Bridge",
        accessor: "acesToNapa",
    },
    "napaConversion": {
        title: "NAPA Conversion",
        accessor: "napaConversion",
    },
    "acesToTecDoc": {
        title: "ACES To TecDoc Bridge",
        accessor: "acesToTecDoc",
    },
    "tecdocConversion": {
        title: "TecDoc Conversion",
        accessor: "tecdocConversion",
    },
    "deliveryDate": {
        title: "Expected Delivery Date",
        accessor: "deliveryDate",
    },
    "additionalInfo": {
        title: "Additional Information",
        accessor: "additionalInfo",
    }
}

const VIOConfig =
{
    headerTitles: {
        contact: "VIO Contact",
        contract: "Contract details",
        usaLightDuty: "USA Light Duty",
        usaHeavyDuty: "USA Medium / Heavy Duty",
        canLightDuty: "CAN Light Duty",
        canHeavyDuty: "CAN Medium / Heavy Duty",
        mexLightDuty: "MEX Light Duty",
        mexHeavyDuty: "MEX Medium / Heavy Duty",
        additionalInfo: "Additional Information",
    },
    "active": {
        title: "Active",
        accessor: "active",
        input: "checkbox",
    },
    "sameAsMainContact": {
        title: "Same as Main Contact",
        accessor: "sameAsMainContact",

    },
    "name": {
        title: "Name",
        accessor: "name",
    },
    "email": {
        title: "Email",
        accessor: "email",
    },
    "phone": {
        title: "Phone",
        accessor: "phone",
    },
    "contractDate": {
        title: "Start / Order / Paid Date",
        accessor: "contractDate",
    },
    "contractEndDate": {
        title: "End Date",
        accessor: "contractEndDate",
    },
    "contractFrequency": {
        title: "Frequency",
        accessor: "contractFrequency",
    },
    "contractActiveForDelivery": {
        title: "Active for Delivery",
        accessor: "contractActiveForDelivery",
    },
    "usaLightDutyActive": {
        title: "Active",
        accessor: "usaLightDutyActive",
    },
    "usaLightDutyNational": {
        title: "National",
        accessor: "usaLightDutyNational",
    },
    "usaLightDutyState": {
        title: "State",
        accessor: "usaLightDutyState",
    },
    "usaLightDutyCounty": {
        title: "County",
        accessor: "usaLightDutyCounty",
    },
    "usaLightDutyZip": {
        title: "Zip",
        accessor: "usaLightDutyZip",
    },
    "usaHeavyDutyActive": {
        title: "Active",
        accessor: "usaHeavyDutyActive",
    },
    "usaHeavyDutyNational": {
        title: "National",
        accessor: "usaHeavyDutyNational",
    },
    "usaHeavyDutyState": {
        title: "State",
        accessor: "usaHeavyDutyState",
    },
    "usaHeavyDutyCounty": {
        title: "County",
        accessor: "usaHeavyDutyCounty",
    },
    "usaHeavyDutyZip": {
        title: "Zip",
        accessor: "usaHeavyDutyZip",
    },
    "canLightDutyActive": {
        title: "Active",
        accessor: "canLightDutyActive",
    },
    "canLightDutyNational": {
        title: "National",
        accessor: "canLightDutyNational",
    },
    "canLightDutyProvince": {
        title: "Province",
        accessor: "canLightDutyProvince",
    },
    "canLightDutyMunicipal": {
        title: "Municipal",
        accessor: "canLightDutyMunicipal",
    },
    "canLightDutyPostalCode": {
        title: "Postal Code",
        accessor: "canLightDutyPostalCode",
    },
    "canHeavyDutyActive": {
        title: "Active",
        accessor: "canHeavyDutyActive",
    },
    "canHeavyDutyNational": {
        title: "National",
        accessor: "canHeavyDutyNational",
    },
    "canHeavyDutyProvince": {
        title: "Province",
        accessor: "canHeavyDutyProvince",
    },
    "canHeavyDutyMunicipal": {
        title: "Municipal",
        accessor: "canHeavyDutyMunicipal",
    },
    "canHeavyDutyPostalCode": {
        title: "Postal Code",
        accessor: "canHeavyDutyPostalCode",
    },
    "mexLightDutyActive": {
        title: "Active",
        accessor: "mexLightDutyActive",
    },
    "mexLightDutyNational": {
        title: "National",
        accessor: "mexLightDutyNational",
    },
    "mexLightDutyState": {
        title: "State",
        accessor: "mexLightDutyState",
    },
    "mexLightDutyMunicipal": {
        title: "Municipal",
        accessor: "mexLightDutyMunicipal",
    },
    "mexHeavyDutyActive": {
        title: "Active",
        accessor: "mexHeavyDutyActive",
    },
    "mexHeavyDutyNational": {
        title: "National",
        accessor: "mexHeavyDutyNational",

    },
    "mexHeavyDutyState": {
        title: "State",
        accessor: "mexHeavyDutyState",

    },
    "mexHeavyDutyMunicipal": {
        title: "Municipal",
        accessor: "mexHeavyDutyMunicipal",
    },
    "additionalInfo": {
        title: "Additional Information",
        accessor: "additionalInfo",
        input: "multilinetext",
    },
}

const OEConfig = {
    headerTitles: {
        options: "OE Options",
        info: "-",
        products: "OE Product Lines",
        additionalInfo: "Additional Information",
    },
    "active": {
        title: "Active",
        accessor: "active",
        input: "checkbox",
    },
    "frequency": {
        title: "Frequency",
        accessor: "frequency",
    },
    "partCatVersion": {
        title: "Partcat Version",
        accessor: "partCatVersion",
    },
    "ftpUsername": {
        title: "FTP Username",
        accessor: "ftpUsername",

    },
    "kTypeDelivery": {
        title: "KType Delivery",
        accessor: "kTypeDelivery",

    },
    "formats": {
        title: "Formats",
        accessor: "formats",
    },
    "dbType": {
        title: "Type",
        accessor: "dbType",
    },
    "dbVersion": {
        title: "Version",
        accessor: "dbVersion",
    },
    "productLines": {
        title: "OE Product Lines",
        accessor: "productLines",

    },
    "additionalInfo": {
        title: "Additional Information",
        accessor: "additionalInfo",
        input: "multilinetext",
    },
    'dataSentAsDMOnly': {
        title: 'Data Sent To DM Only',
        accessor: 'dataSentToDMOnly'
    }
}

const PCOConfig =
{
    headerTitles: {
        options: "PCO Options",
        setupInfo: "Setup Information",
        dataToLoad: "Data to load",
        additionalInfo: "Additional Information",
    },
    "active": {
        title: "Active",
        accessor: "active",
    },
    "contractLength": {
        title: "Contract Length",
        accessor: "contractLength",
        accessor2: "lengthType",
    },
    "adminAccount": {
        title: "Admin Account",
        accessor: "adminAccount",
    },
    "additionalAccounts": {
        title: "Additional Accounts",
        accessor: "additionalAccounts"
    },
    "brandAAIA": {
        title: "Brand AAIA",
        accessor: "brandAAIA",
    },
    "customerId": {
        title: "Customer ID",
        accessor: "customerId",
    },
    "digitalAssets": {
        title: "Digital Assets",
        accessor: "digitalAssets",
    },
    "expectedNumberOfParts": {
        title: "Expected Number of Parts",
        accessor: "expectedNumberOfParts",
    },
    "vcdbTypes": {
        title: "VCDB",
        accessor: "vcdbTypes",
    },
    "hasPADB": {
        title: "PADB",
        accessor: "hasPADB",
    },
    "hasACES": {
        title: "Has ACES",
        accessor: "hasACES",
    },
    "additionalInfo":
    {
        title: "Additional Information",
        accessor: "additionalInfo",
    },
    "onboardingRequired": {
        title: "Onboarding Required",
        accessor: "onboardingRequired",
    },
    "hasACESXML": {
        accessor: "hasACESXML",
        title: "ACES XML",
    },
    "hasACESSpreadsheet": {
        accessor: "hasACESSpreadsheet",
        title: "ACES Spreadsheet",
    },
    "hasPIESXML": {
        accessor: "hasPIESXML",
        title: "PIES XML",
    },
    "hasPIESSpreadsheet": {
        accessor: "hasPIESSpreadsheet",
        title: "PIES Spreadsheet",
    },
    "conversionNeeded": {
        title: "Conversion Needed",
        accessor: "conversionNeeded",
    },
    "hasQDB": {
        title: "QDB",
        accessor: "hasQDB"
    }
}

export {
    DBTypes,
    ContractFrequency,
    DefaultCustomer,
    DefaultOptions,
    DeliveryType,
    Lengths,
    Hardware,
    Hosting,
    OeDataSource,
    ResearchType,
    Status,
    Types,
    UpdateFrequency,
    VioDataRegion,
    VioDataLevel,
    requestStatus,
    requestStatusDescription,
    shortRequestStatusDescription,
    requestTypes,
    requestTypesDescription,
    DefaultRequest,
    DefaultTitle,
    Regions,
    Availabilities,
    OeCategories,
    OeFrequency,
    defaultProduct,
    PCOVCDBTypes,
    OnboardingRequired,
    OEFormats,
    PartCatConfig,
    DMConfig,
    VIOConfig,
    OEConfig,
    PCOConfig,
};