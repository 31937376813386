import React from 'react';
import { Box } from '@mui/material';
import FormTemplate from './FormTemplate';

import VioOptionsForm from './form-definitions/VioOptionsForm';

const VioOptions = ({ options, setOption, errors, isDisplay, showCustomerOptions, customerOptions, defaultOptions }) => {

    const formDefinition = VioOptionsForm();

    return (
        <Box>
            <FormTemplate
                data={formDefinition}
                id="VIO"
                options={options}
                errors={errors}
                isDisplay={isDisplay}
                setOption={setOption}
                showCustomerOptions={showCustomerOptions}
                customerOptions={customerOptions}
                defaultOptions={defaultOptions}
            />
        </Box>
    )
   
}

export default VioOptions;