import React from 'react';
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import { 
    reverseRequestType, 
    reverseRequestStatus,
    createFilterDescription } from '../../utils';
import { convertObjectFieldISOtoLocal } from '../../utils/time_utc_local';
import { 
    shortRequestStatusDescription, 
    requestTypesDescription } from '../../constants/DefaultOptions';

const CustomerRequests = ({requests}) => {

    const convertedRequests = requests.map(convertObjectFieldISOtoLocal('date')).map(convertObjectFieldISOtoLocal('lastUpdate'));
    const columnDefs = [
        {
            Header: 'Type',
            accessor: reverseRequestType,
            filter: createFilterDescription(requestTypesDescription, (o) => o.type)
        },
        {
            Header: 'Status',
            accessor: reverseRequestStatus,
            filter: createFilterDescription(shortRequestStatusDescription, (o) => o.status)
        },
        {
            Header: 'Last Update User',
            accessor: 'lastUpdateUser'
        },
        {
            Header: 'Last Modified',
            accessor: 'date'
        }
    ];

    const setSelectedRows = (selectedFlatRows) => {};

    return (
        <DivTableStyles>
            <JnpDivTable
                columns={columnDefs}
                data={convertedRequests}
                selectable={false}
                disableSelect={true}
                setSelectedRows={setSelectedRows}
                defaultSortColumn="Type"
                title="Customer Requests"
                backGroundColor="#003061"
            />
        </DivTableStyles>
    );
}

export default CustomerRequests;