import axios from 'axios';

const ApiUrl = process.env.REACT_APP_SALES_PORTAL_API_URL;

const log = (m) => console.log(m);

const get = (uri, onData = log, onError = log) => {
    axios.get(`${ApiUrl}/${uri}`)
    .then(response => onData(response.data))
    .catch(onError);
};

const post = (uri, postData, onData = log, onError = log, options = {}) => {
    axios.post(`${ApiUrl}/${uri}`, postData, options)
    .then(response => onData(response))
    .catch(onError);
};

const del = (uri, onData = log, onError = log) => {
    axios.delete(`${ApiUrl}/${uri}`)
    .then(response => onData(response))
    .catch(onError);
};

export const getAllCustomers = (onData, onError) => {
    get('Customer', onData, onError);
}

export const getCustomer = (customerId, onData, onError) => {
    get(`Customer/${customerId}`, onData, onError);
}

export const saveCustomer = (postData, onData, onError) => {
    post('Customer', postData, onData, onError);
}

export const getCustomerRequest = (customerRequestId, onData, onError) => {
    get(`CustomerRequest/customer-request/${customerRequestId}`, onData, onError);
}

export const saveCustomerRequest = (postData, onData, onError) => {
    post('CustomerRequest', postData, onData, onError);
}

export const getConfiguration = (onData, onError) => {
    get('Configuration', onData, onError);
}

export const approveCustomer = (postData, requestedTypes, onData, onError) => {
    const params = new URLSearchParams();

    if (requestedTypes) {
        requestedTypes.forEach((type) => params.append('requestedTypes', type));
    }

    post(`Customer/approve?${params}`, postData, onData, onError);
}

export const rejectChangesCustomer = (postData, requestedTypes, onData, onError) => {
    const params = new URLSearchParams();
    if (requestedTypes) {
        requestedTypes.forEach((type) => params.append('requestedTypes',type));
    }
    post(`Customer/rejectChanges?${params}`, postData, onData, onError);
}


export const rejectCustomer = (postData, onData, onError) => {
    post('Customer/reject', postData, onData, onError);
}

export const abandonCustomer = (postData, onData, onError) => {
    post('Customer/abandon', postData, onData, onError);
}

export const terminateCustomer = (postData, onData, onError) => {
    post('Customer/terminate', postData, onData, onError);
}

export const deleteCustomer = (customerId, onData, onError) => {
    del(`Customer/${customerId}`, onData, onError);
}

export const getAllAAIABrands = (onData, onError) => {
    get('Configuration/aaia-brands', onData, onError);
}

export const downloadLicenseFilesZip = (onData, onError) => {
    post('Configuration/pco-licenses', {}, onData, onError, {responseType: 'blob'});
}

export const downloadOELicenseFilesZip = (onData, onError) => {
    post('Configuration/oe-licenses', {}, onData, onError, {responseType: 'blob'});
}

export const getOeProducts = (onData, onError) => {
    get('oeproduct', onData, onError);
}