import { reverseDBTypes, reverseOeFrequency } from '../../../utils';
import { OeFrequency, DBTypes, OEFormats, OEConfig } from '../../../constants/DefaultOptions';

export default (handleOpen) => [
  {
      headerTitle: OEConfig.headerTitles.options,
      defaultExpanded: true,
      sections: [
          {
              title: OEConfig.active.title,
              accessor: OEConfig.active.accessor,
              input: "checkbox",
          },
          {
              title: OEConfig.frequency.title,
              accessor: OEConfig.frequency.accessor,
              input: "dropdown",
              value: OeFrequency,
              reverse: reverseOeFrequency,
          },
          {
              title: OEConfig.partCatVersion.title,
              accessor: OEConfig.partCatVersion.accessor,
              input: "text",
          },
          {
              title: OEConfig.ftpUsername.title,
              accessor: OEConfig.ftpUsername.accessor,
              input: "text"
          },
      ]
  },
  {
      headerTitle: OEConfig.headerTitles.info,
      defaultExpanded: true,
      sections: [
          {
              title: OEConfig.formats.title,
              accessor: OEConfig.formats.accessor,
              input: "multiselect",
              value: OEFormats,
          },
          {
              title: OEConfig.dbType.title,
              accessor: OEConfig.dbType.accessor,
              input: "radio",
              value: DBTypes,
              reverse: reverseDBTypes,
              accessorDep: options => !options?.formats?.includes("SQL")
          },
          {
              title: OEConfig.dbVersion.title,
              accessor: OEConfig.dbVersion.accessor,
              input: "text",
              accessorDep: options => !options?.formats?.includes("SQL")
          },
          {
            title: OEConfig.dataSentAsDMOnly.title,
            accessor: OEConfig.dataSentAsDMOnly.accessor,
            input: "checkbox"
        },
      ]
  },
  {
      headerTitle: OEConfig.headerTitles.products,
      sections: [

          {
              title: OEConfig.productLines.title,
              accessor: OEConfig.productLines.accessor,
              input: "productlines",
              editAction: handleOpen,
          }
      ]
  },
  {
      headerTitle: OEConfig.headerTitles.additionalInfo,
      sections: [
          {
              title: OEConfig.additionalInfo.title,
              accessor: OEConfig.additionalInfo.accessor,
              input: "multilinetext",
          }
      ]
  },
]