import React from 'react';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GeneralAutocomplete = (props) => {
    
    const {
        label,
        keyPropertyName,
        itemValue,
        setItemValue,
        options,
        value,
        handleError = () => false,
        getHelperText = () => '',
        multiple = false,
        containerStyle = {},
        ...otherParams
    } = props;

    const handleOnChange = (e, val, r) => {
        var selectedValues = multiple && Array.isArray(val) ? val.map(x => x.value) : val?.value;
        const updatedObj = { ...itemValue, [keyPropertyName]: selectedValues };
        setItemValue(updatedObj, val);
    };

    const getOptionLabel = (option) => {
        return option.label || '';
    };

    return (
        <Box sx={{ ...containerStyle }}>
            <Autocomplete
                getOptionLabel={getOptionLabel}
                size="small"
                onChange={handleOnChange}
                multiple={multiple}
                sx={{
                    '& .MuiInputBase-root' :{
                        paddingTop: '1px!important',
                        paddingBottom: '2px!important',
                        paddingLeft: '0!important'
                    },
                    '& .MuiAutocomplete-input': {
                        paddingLeft:'4px!important',
                    },
                    '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
                        padding: '4px 0 0 0!important'
                    }
                }}
                options={options}
                disableCloseOnSelect={multiple}
                isOptionEqualToValue={(option, value) => !Boolean(value) || option.value == value.value}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={getOptionLabel(option)} style={{fontSize: '0.8em', height: "30px"}}>
                        {
                            multiple ?
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                size="small"
                            />: undefined
                        }
                        {getOptionLabel(option)}
                    </li>
                )}
                {...otherParams}
                value={value ?? ' '}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) =>
                        <Chip
                            label={getOptionLabel(option)}
                            size="small"
                            {...getTagProps({ index })}
                            key={index}
                            sx={{
                                minWidth: 240,
                                justifyContent: 'space-between'
                            }}
                        />
                )
            }
            limitTags={5}
            renderInput={({InputLabelProps, ...otherInputParams}) => 
                <TextField
                    color="primary"
                    variant="outlined"
                    size="small"
                    sx={{padding:0}}
                    fullWidth
                    error={handleError(keyPropertyName)}
                    helperText={getHelperText(keyPropertyName)}
                    InputLabelProps={{ shrink: true }}
                    {...otherInputParams}
                />
            }
        />
        </Box>
    );
};

export default GeneralAutocomplete;