import { DeliveryType, Lengths, OeDataSource, ResearchType, UpdateFrequency, VioDataLevel, VioDataRegion, OECustomerType } from '../../../constants/DefaultOptions';
import { DMConfig } from '../../../constants/DefaultOptions';
import { reverseOECustomerType } from '../../../utils';

export default (handleOpen, additionalColumns) => [
  {
      headerTitle: DMConfig.headerTitles.dataManagement,
      sections: [
          {
            title: DMConfig.active.title,
            accessor: DMConfig.active.accessor,
            input: "checkbox",
          },
          {
              title: DMConfig.contractLength.title,
              accessor: DMConfig.contractLength.accessor,
              input: "text",
          },
          {
              title: DMConfig.lengthType.title,
              accessor: DMConfig.lengthType.accessor,
              input: "radio",
              value: Lengths,
          },
          {
              title: DMConfig.startDate.title,
              accessor: DMConfig.startDate.accessor,
              input: "startDate",
              type: "date",
              dateContractEndAccessor: DMConfig.endDate.accessor,
          },
          {
              title: DMConfig.endDate.title,
              accessor: DMConfig.endDate.accessor,
              input: "text",
              type: "date",
          },
      ]
  },
  {
      headerTitle: DMConfig.headerTitles.application,
      sections: [
          {
              title: DMConfig.existingAces.title,
              accessor: DMConfig.existingAces.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.acesDeliveryType.title,
              accessor: DMConfig.acesDeliveryType.accessor,
              input: "radio",
              value: {
                  XML: { label: "XML", value: 1 },
                  Excel: { label: "Excel", value: 2 },
              },
          },
          {
              title: DMConfig.buildDataFromOe.title,
              accessor: DMConfig.buildDataFromOe.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.numberOfOePart.title,
              accessor: DMConfig.numberOfOePart.accessor,
              input: "text",
          },
          {
              title: DMConfig.researchType.title,
              accessor: DMConfig.researchType.accessor,
              input: "radio",
              value: ResearchType,
          },
          {
              title: DMConfig.crossReferenceExisting.title,
              accessor: DMConfig.crossReferenceExisting.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.heavyDuty.title,
              accessor: DMConfig.heavyDuty.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.customData.title,
              accessor: DMConfig.customData.accessor,
              input: "checkbox",
          },
      ]
  },

  {
      headerTitle: DMConfig.headerTitles.pies,
      sections: [
          {
              title: DMConfig.piesExistingPies.title,
              accessor: DMConfig.piesExistingPies.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.piesDeliveryType.title,
              accessor: DMConfig.piesDeliveryType.accessor,
              input: "radio",
              value: DeliveryType,
          },
          {
              title: DMConfig.piesRebranding.title,
              accessor: DMConfig.piesRebranding.accessor,
              input: "checkbox",
          },
      ]
  },

  {
      headerTitle: DMConfig.headerTitles.padb,
      sections: [
          {
              title: DMConfig.padbExistingPies.title,
              accessor: DMConfig.padbExistingPies.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.padbDeliveryType.title,
              accessor: DMConfig.padbDeliveryType.accessor,
              input: "radio",
              value: {
                  XML: { label: "XML", value: 1 },
                  Excel: { label: "Excel", value: 2 },
              },
          },
      ]
  },

  {
      headerTitle: DMConfig.headerTitles.digital,
      sections: [
          {
              title: DMConfig.physicalImages.title,
              accessor: DMConfig.physicalImages.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.piesDigitalAssetImages.title,
              accessor: DMConfig.piesDigitalAssetImages.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.physical360Images.title,
              accessor: DMConfig.physical360Images.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.piesDigitalAsset360Images.title,
              accessor: DMConfig.piesDigitalAsset360Images.accessor,
              input: "checkbox",

          },
      ]
  },
  {
      headerTitle: DMConfig.headerTitles.maintenance,
      sections: [
          {
              title: DMConfig.updateFrequency.title,
              accessor: DMConfig.updateFrequency.accessor,
              input: "radio",
              value: UpdateFrequency,

          },
          {
              title: DMConfig.partUpdateQuota.title,
              accessor: DMConfig.partUpdateQuota.accessor,
              input: "text",
          },
      ]
  },
  {
      headerTitle: DMConfig.headerTitles.oe,
      sections: [
          {
              title: DMConfig.oeDataActive.title,
              accessor: DMConfig.oeDataActive.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.oeDataSource.title,
              accessor: DMConfig.oeDataSource.accessor,
              input: "radio",
              value: OeDataSource,
          },
          {
              title: DMConfig.oeDataCarryOver.title,
              accessor: DMConfig.oeDataCarryOver.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.oeDataShoppingList.title,
              accessor: DMConfig.oeDataShoppingList.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.oeCustomerType.title,
              accessor: DMConfig.oeCustomerType.accessor,
              input: "radio",
              value: OECustomerType,
              reverse: reverseOECustomerType,
          },
      ]
  },
  {
    headerTitle: DMConfig.headerTitles.oeProducts,
    sections: [
        {
            title: DMConfig.oeDataProductLines.title,
            accessor: DMConfig.oeDataProductLines.accessor,
            input: "productlines",
            editAction: handleOpen,
            additionalColumns
        },
    ]
  },
  {
      headerTitle: DMConfig.headerTitles.tecdoc,
      sections: [
          {
              title: DMConfig.tecdocActive.title,
              accessor: DMConfig.tecdocActive.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.tecdocOeCrossReference.title,
              accessor: DMConfig.tecdocOeCrossReference.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.tecdocApplicationData.title,
              accessor: DMConfig.tecdocApplicationData.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.tecdocPiesData.title,
              accessor: DMConfig.tecdocPiesData.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.tecdocPadbData.title,
              accessor: DMConfig.tecdocPadbData.accessor,
              input: "checkbox",
          },
          {
              title: DMConfig.tecdocDigitalAssetData.title,
              accessor: DMConfig.tecdocDigitalAssetData.accessor,
              input: "checkbox",
          },
      ]
  },
  {
      headerTitle: DMConfig.headerTitles.vio,
      sections: [
          {
              title: DMConfig.vioDataActive.title,
              accessor: DMConfig.vioDataActive.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.vioDataRegion.title,
              accessor: DMConfig.vioDataRegion.accessor,
              input: "radio",
              value: VioDataRegion,
          },
          {
              title: DMConfig.vioDataLevel.title,
              accessor: DMConfig.vioDataLevel.accessor,
              input: "radio",
              value: VioDataLevel,
          },

      ]
  },

  {
      headerTitle: DMConfig.headerTitles.napa,
      sections: [
          {
              title: DMConfig.napaDataActive.title,
              accessor: DMConfig.napaDataActive.accessor,
              input: "checkbox",

          },
          {
              title: DMConfig.napaDataConversion.title,
              accessor: DMConfig.napaDataConversion.accessor,
              input: "checkbox",
          },

      ]
  },


  {
      headerTitle: DMConfig.headerTitles.opticat,
      sections: [
          {
              title: DMConfig.opticatSubscription.title,
              accessor: DMConfig.opticatSubscription.accessor,
              input: "checkbox",

          },
      ]
  },

  {
      headerTitle: DMConfig.headerTitles.objective,
      sections: [
          {
              title: DMConfig.objectiveShortTerm.title,
              accessor: DMConfig.objectiveShortTerm.accessor,
              input: "text",
          },
          {
              title: DMConfig.objectiveMidTerm.title,
              accessor: DMConfig.objectiveMidTerm.accessor,
              input: "text",

          },
          {
              title: DMConfig.objectiveLongTerm.title,
              accessor: DMConfig.objectiveLongTerm.accessor,
              input: "text",

          },
          {
              title: DMConfig.objectivePromiseToCustomer.title,
              accessor: DMConfig.objectivePromiseToCustomer.accessor,
              input: "text",

          },
          {
              title: DMConfig.objectiveTimeline.title,
              accessor: DMConfig.objectiveTimeline.accessor,
              input: "text",
          },
      ]
  },
  {
    headerTitle: '-',
    sections: []
  }
];