import { Box, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';


const GeneralCheckBox = (props) => {

    const {
        keyPropertyName,
        disabled = false,
        itemValue,
        setItemValue,
        label = '',
        containerStyle = {},
        subsections = [],
        ...otherProps
    } = props;

    const handleChange = (event) => {
        const isChecked = event.target.checked;
        let updatedObj = { ...itemValue };
        updatedObj[keyPropertyName] = isChecked
        if (subsections.length > 0) {
            subsections.forEach(subsection => {
                if (!isChecked && subsection.accessor in itemValue && itemValue[subsection.accessor])
                    updatedObj[subsection.accessor] = false
            });
        }
        setItemValue(updatedObj);
    };

    const name = `check-box-${keyPropertyName}`;
    
    return (
        <Box sx={{ display: 'flex', ...containerStyle }}>
            <Checkbox
                checked={!!itemValue[keyPropertyName]}
                onChange={handleChange}
                disabled={disabled}
                name={name}
                size="small"
                sx={{
                    padding: '0!important'
                }}
                {...otherProps}
            />
            <Typography style={{ padding: '2px 0 0 5px' }}>{label}</Typography>
        </Box>
    );
}

export default GeneralCheckBox;