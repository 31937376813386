import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TabPanel from '../TabPanel';
import { useNavigate } from "react-router-dom";
import { a11yProps, TabsHeight } from '../../utils';
import GeneralOptions from './GeneralOptions';
import PartCatOptions from './PartCatOptions';
import DMOptions from './DMOptions';
import VioOptions from './VioOptions';
import OEOptions from './OEOptions';
import PCOOptions from './PCOOptions';
import CustomerRequests from './CustomerRequests';
import { configureActionBar, buildCustomerActions, configureDisabled, compareActions } from '../../services/ActionBarService';
import { Permissions } from '../../models/user-profile';
import { Status, DefaultTitle, DefaultOptions, OEConfig, DMConfig } from '../../constants/DefaultOptions';
import { styled } from '@mui/system';
import ListAltIcon from '@mui/icons-material/ListAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ProductsSummary from '../ProductsSummary';
import _ from 'lodash';
import ApproveConfirmDialog from '../../dialogs/ApproveConfirmDialog';
import RejectChangesConfirmDialog from '../../dialogs/RejectChangesConfirmDialog';
import '../../styles/toolbar.css';

const CustomerOptions = ({
    customer,
    setOptions,
    isDisplay,
    setDisplay,
    saveSubmit,
    saveIncomplete,
    approve,
    reject,
    abandon,
    terminate,
    rejectChanges,
    refOptions,
    config,
    title,
    actionInfos,
    setActionInfos
 }) => {

    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const [errors, setErrors] = useState({});
    const [isManager, setIsManager] = useState(false);
    const [actions, setActions] = useState([]);
    const [originalRefOptions, setOriginalRefOptions] = useState(null);
    const [confirmRequestedTypesOpen, setConfirmRequestedTypesOpen] = useState(false);
    const [confirmRejectChangesOpen, setConfirmRejectChangesOpen] = useState(false);
    const editOptions = () => setDisplay(false);

    const goBack = () => navigate("/");

    const cancelAction = (rollBack = true) => {
        if (title === DefaultTitle) {
            navigate("/");
        } else {
            if (rollBack) {
                setOptions(originalRefOptions ?? refOptions);
                setDisplay(true);
            } else {
                setDisplay(true);
            }
        }
    }

    const onSaveSubmit = () => {
        setTimeout(() => {
            saveSubmit();
            cancelAction(false);
        }, 250);
    }

    const onSaveIncomplete = () => {
        setTimeout(() => {
            saveIncomplete();
            cancelAction(false);
        }, 250);
    }

    useEffect(() => {

        const newActions = buildCustomerActions(isDisplay, isManager);

        if (!compareActions(newActions, actionInfos?.actions))
        {
            setActions(newActions); 
        }

        if (customer?.customerID && !originalRefOptions)
        {
            const originalRequestOptions = customer?.requests?.find(x => x.customerRequestID === customer?.currentRequest?.customerRequestID)?.options;
            setOriginalRefOptions(originalRequestOptions);
        }
      

    }, [customer, isDisplay, isManager]);

    useEffect(() => {
        const newErrors = {...errors,
            companyName: customer.currentRequest.options.companyName && customer.currentRequest.options.companyName.length ? undefined : "Company name is required",
            companyAddress: customer.currentRequest.options.companyAddress && customer.currentRequest.options.companyAddress.length ? undefined : "Company address is required",
        };

        if(!_.isEqual(errors, newErrors))
        {
            setErrors({...errors, ...newErrors});
        }

        configureActionBar(actions, actionInfos, setActionInfos);

    }, [actions]);

    useEffect(() => {
        if (!isManager && config?.permissions?.includes(Permissions.MANAGER)) {
            setIsManager(true);
        }
    }, [config]);

    useEffect(() => {
        if (actionInfos.actionClicked) {
            dispatchAction(actionInfos.actionClicked);
        }
    }, [actionInfos.actionClicked]);

    useEffect(() => {
        configureSave(errors, customer, refOptions, actionInfos, setActionInfos);
        configureRequestControls();
    }, [refOptions, actionInfos, errors]);

    const dispatchAction = (action) => {
        switch(action) {
            case 'save-submit': onSaveSubmit(); break;
            case 'save-incomplete': onSaveIncomplete(); break;
            case 'approve': handleApprove(); break;
            case 'reject': reject(); break;
            case 'rejectChanges': handleRejectChanges(); break;
            case 'back': goBack(); break;
            case 'edit': editOptions(); break;
            case 'terminate': terminate(); break;
            case 'abandon': abandon(); break;
            case 'cancel': cancelAction(); break;
            default: break;
        }
        setActionInfos({...actionInfos, actionClicked: null});
    }

    const configureSave = (errors, customer, refOptions, actionInfos, setActionInfos) => {
        const disabledSaveAndSubmit = Object.values(errors).filter(x => x).length > 0;
        const disabledIncomplete = disabledSaveAndSubmit || JSON.stringify(customer.currentRequest.options) === JSON.stringify(refOptions);
        configureDisabled("save-submit", disabledSaveAndSubmit, actionInfos, setActionInfos);
        configureDisabled("save-incomplete", disabledIncomplete, actionInfos, setActionInfos);
    }

    const configureRequestControls = () => {
        const hasUser = config?.permissions?.includes(Permissions.USER) || config?.permissions?.includes(Permissions.MANAGER);
        const disableEdit = !hasUser || customer.status === Status.PENDING_TERMINATION_APPROVAL;
        const disabledApprove = customer.status !== Status.PENDING_CREATION_APPROVAL &&
            customer.status !== Status.PENDING_UPDATE_APPROVAL && customer.status !== Status.PENDING_TERMINATION_APPROVAL;
        const disabledReject = customer.status !== Status.PENDING_CREATION_APPROVAL && customer.status !== Status.PENDING_UPDATE_APPROVAL;
        const disabledRejectChanges =customer.status !== Status.PENDING_CREATION_APPROVAL &&
        customer.status !== Status.PENDING_UPDATE_APPROVAL && customer.status !== Status.PENDING_TERMINATION_APPROVAL;
        const disableAbandon = !hasUser || customer.status === Status.ACTIVE;
        const disableTerminate = !hasUser || customer.status !== Status.ACTIVE;
        configureDisabled("edit", disableEdit, actionInfos, setActionInfos);
        configureDisabled("approve", disabledApprove, actionInfos, setActionInfos);
        configureDisabled("reject", disabledReject, actionInfos, setActionInfos);
        configureDisabled("rejectChanges", disabledRejectChanges, actionInfos, setActionInfos);
        configureDisabled("abandon", disableAbandon, actionInfos, setActionInfos);
        configureDisabled("terminate", disableTerminate, actionInfos, setActionInfos);
    }

    const handleApprove = () => {
        if (customer.status === Status.PENDING_UPDATE_APPROVAL || customer.status === Status.PENDING_CREATION_APPROVAL) {
            setConfirmRequestedTypesOpen(true);
        }
        else {
            approve();
        }
    }
    const handleConfirm = (requestedTypes) => {
        approve(requestedTypes);
        setConfirmRequestedTypesOpen(false);
    }
    const handleRejectChanges = () => {
        if (customer.status === Status.PENDING_UPDATE_APPROVAL || customer.status === Status.PENDING_CREATION_APPROVAL) {
            setConfirmRejectChangesOpen(true);
        }
        else {
            approve();
        }
    }
    const handleConfirmRejectChanges = (requestedTypes) => {
        rejectChanges(requestedTypes);
        setConfirmRejectChangesOpen(false);
    }

    const handleCancelRejectChanges = () => {
        setConfirmRejectChangesOpen(false);
    }

    const handleCancel = () => {
        setConfirmRequestedTypesOpen(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setGeneralOptions = (newOptions) => {

        var newErrors = {
            companyName: newOptions.companyName && newOptions.companyName.length ? undefined : "Company name is required",
            companyAddress: newOptions.companyAddress && newOptions.companyAddress.length ? undefined : "Company address is required",
        };

        setErrors({...errors, ...newErrors});
        setOptions(newOptions);
    }

    const setPartCatOptions = (newOptions) => {
        setOptions({ ...customer.currentRequest.options, partcat_options: newOptions });
    }

    const setDMOptions = (newOptions) => {
        let newErrors = {...errors};
        newErrors = {...newErrors, ...validateProductLines(newOptions, DMConfig.oeDataProductLines.accessor)};
        setErrors(newErrors);
        setOptions({ ...customer.currentRequest.options, dm_options: newOptions });
    }

    const setVioOptions = (newOptions) => {
        setOptions({ ...customer.currentRequest.options, vio_options: newOptions });
    }

    const setOEOptions = (newOptions) => {
        let newErrors = {...errors};
        newErrors = {...newErrors, ...validateProductLines(newOptions, OEConfig.productLines.accessor)};
        setErrors(newErrors);

        if (!newOptions?.formats?.includes("SQL"))
        {
            newOptions.dbType = DefaultOptions.oe_options.dbType;
            newOptions.dbVersion = DefaultOptions.oe_options.dbVersion;
        }

        setOptions({ ...customer.currentRequest.options, oe_options: newOptions });
    }

    const setPCOOptions = (newOptions) => {
        setOptions({ ...customer.currentRequest.options, pco_options: newOptions });
    }

    const validateProductLines = (newOptions, accessor) => {
        const productLines = newOptions[accessor];
        const isInformationMissing = productLines.filter(x => Object.entries(x).filter(y => 
            y[0] !== 'carryOver'
            && y[0] !== 'shoppingList'
            && (!!!y[1] || y[1]?.length === 0) ).length > 0).length > 0;

        if (isInformationMissing)
        {
            return {[accessor]: 'Please fill all the missing fields' }
        }

        const isInformationDuplicated = 
            productLines.filter(
                (x, i) => 
                    productLines.filter(
                        (y, j) => 
                            i !== j 
                            && x?.productLineId === y?.productLineId
                            && x?.regions === y?.regions ).length > 0
                    ).length > 0;
        
        if (isInformationDuplicated)
        {
            return {[accessor]: 'Please remove the duplicates' }
        }

        return {[accessor]: undefined }
    }

    const getTabStyle = (permission) => {
        return config?.permissions?.includes(permission) ? {} : { display: 'none' };
    }

    const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        minWidth: "160px",
        color: 'rgba(255, 255, 255, 0.7)',
        '&.Mui-selected': {
            color: '#fff'
        },
    }),
    );

 
    const [state, setState] = useState({open: false});
    const handleOpen = () => setState({open: true});
    const handleClose = () => setState({open: false});

    return (
        <div style={{
            flexGrow: 1,
            height: "100%",
        }}>
            <AppBar position="static" sx={{ bgcolor: "rgb(0, 82, 207)" }}>
                <Grid container>
                    <Grid item xs={10}>
                        <Tabs value={value} onChange={handleChange}
                            indicatorColor='secondary'
                            sx={{ height: TabsHeight }}>
                            <StyledTab label="General" {...a11yProps(0)} />
                            <StyledTab label="PartCat" {...a11yProps(1)} style={getTabStyle(Permissions.PARTCAT)} />
                            <StyledTab label="DM" {...a11yProps(2)} style={getTabStyle(Permissions.DM)} />
                            <StyledTab label="VIO" {...a11yProps(3)} style={getTabStyle(Permissions.VIO)} />
                            <StyledTab label="OE" {...a11yProps(4)} style={getTabStyle(Permissions.OE)} />
                            <StyledTab label="PCO" {...a11yProps(5)} style={getTabStyle(Permissions.PCO)} />
                            <StyledTab label="Requests" {...a11yProps(6)} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row" justifyContent="end">
                            <Tooltip title="Customer Summary">
                                <IconButton onClick={handleOpen}>
                                    <ListAltIcon sx={{ color: "#FFF" }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </AppBar>
            <TabPanel value={value} index={0} key={0} style={{ overflowY: "initial" }}>
                <GeneralOptions
                    companyStatus={customer.status}
                    options={customer.currentRequest.options}
                    setOption={setGeneralOptions}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options}
                />
            </TabPanel>
            <TabPanel value={value} index={1} key={1}>
                <PartCatOptions
                    options={customer.currentRequest.options.partcat_options}
                    setOption={setPartCatOptions}
                    defaultOptions={DefaultOptions.partcat_options}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options.partcat_options}
                />
            </TabPanel>
            <TabPanel value={value} index={2} key={2}>
                <DMOptions
                    options={customer.currentRequest.options.dm_options}
                    oeOptions={customer.currentRequest.options.oe_options}
                    setOption={setDMOptions}
                    defaultOptions={DefaultOptions.dm_options}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options.dm_options}
                />
            </TabPanel>
            <TabPanel value={value} index={3} key={3} >
                <VioOptions
                    options={customer.currentRequest.options.vio_options}
                    setOption={setVioOptions}
                    defaultOptions={DefaultOptions.vio_options}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options.vio_options}
                />
            </TabPanel>
            <TabPanel value={value} index={4} key={4}>
                <OEOptions
                    options={customer.currentRequest.options.oe_options}
                    setOption={setOEOptions}
                    defaultOptions={DefaultOptions.oe_options}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options.oe_options}
                />
            </TabPanel>
            <TabPanel value={value} index={5} key={5}>
                <PCOOptions
                    options={customer.currentRequest.options.pco_options ?? DefaultOptions.pco_options}
                    setOption={setPCOOptions}
                    defaultOptions={DefaultOptions.pco_options}
                    errors={errors}
                    isDisplay={isDisplay}
                    showCustomerOptions={customer.showCustomerOptions}
                    customerOptions={customer.options.pco_options}
                />
            </TabPanel>
            <TabPanel value={value} index={6} key={6}>
                <CustomerRequests requests={customer.requests} />
            </TabPanel>
            <ProductsSummary 
                open={state.open}
                customer={!!customer?.customerRequestID ? customer.currentRequest : customer}
                handleClose={handleClose}
                companyName = {customer.options.companyName !== "" ? customer.options.companyName : customer.currentRequest.options.companyName}
            >

            </ProductsSummary>
            <ApproveConfirmDialog
                open={confirmRequestedTypesOpen}
                activeRequestTypes={customer?.activeRequestTypes}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
            />
               <RejectChangesConfirmDialog
                open={confirmRejectChangesOpen}
                activeRequestTypes={customer?.activeRequestTypes}
                handleConfirm={handleConfirmRejectChanges}
                handleCancel={handleCancelRejectChanges}
            />

        </div>
    );
}

export default CustomerOptions;
