import { ReverseOptions } from '../utils';
import { PartCatConfig, DMConfig, VIOConfig, OEConfig, PCOConfig } from './DefaultOptions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const createProductLineTable = (arrProductLines) => {
  return (
      <TableContainer component={Paper}>
          <Table size="small" sx={{
              th: { paddingTop: '2px', paddingBottom: '2px', borderBottom: 0, fontSize: "0.85rem" },
              td: { fontSize: "0.80rem" }
          }}>
              <TableHead>
                  <TableRow>
                      <TableCell>Product Line</TableCell>
                      <TableCell>Year From</TableCell>
                      <TableCell>Year To</TableCell>

                  </TableRow>
              </TableHead>
              <TableBody>
                  {Object.entries(arrProductLines).map(([key, row]) => {
                      return (
                          <TableRow
                              key={key}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell>{row.productLineName} {row.regions != "" ? "(" + row.regions + ")" : ""}</TableCell>
                              <TableCell>{row.yearFrom}</TableCell>
                              <TableCell>{row.yearTo}</TableCell>
                          </TableRow>
                      )
                  })}
              </TableBody>
          </Table>
      </TableContainer >
  )
}

export const ProductsSummaryDefinitions = {
  PartCat: [{
      headerTitle: PartCatConfig.headerTitles.partcatOption,
      sections: {
          "contractLength": {
              title: PartCatConfig.contractLength.title,
              accessor: PartCatConfig.contractLength.accessor,
              accessor2: PartCatConfig.contractLength.accessor2,

          },
          "lengthType": {
              title: PartCatConfig.lengthType.title,
              accessor: PartCatConfig.lengthType.accessor,
              reverse: ReverseOptions.partcat_options.lengthType
          },
          "startDate": {
              title: PartCatConfig.startDate.title,
              accessor: PartCatConfig.startDate.accessor,
          },
          "licenseLength": {
              title: PartCatConfig.licenseLength.title,
              accessor: PartCatConfig.licenseLength.accessor,
              accessor2: PartCatConfig.licenseLength.accessor2,
              reverse: ReverseOptions.partcat_options.licenseLength
          },
          "licenseType": {
              title: PartCatConfig.licenseType.title,
              accessor: PartCatConfig.licenseType.accessor,
              reverse: ReverseOptions.partcat_options.licenseType,
          },
      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.autocare,
      sections: {
          "autoCareSubscription": {
              title: PartCatConfig.autoCareSubscription.title,
              accessor: PartCatConfig.autoCareSubscription.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "vcdb": {
              title: PartCatConfig.vcdb.title,
              accessor: PartCatConfig.vcdb.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "vcdbType": {
              title: PartCatConfig.vcdbType.title,
              accessor: PartCatConfig.vcdbType.accessor,
          },
          "padb": {
              title: PartCatConfig.padb.title,
              accessor: PartCatConfig.padb.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.software,
      sections: {
          "publishing": {
              title: PartCatConfig.publishing.title,
              accessor: PartCatConfig.publishing.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "customReport": {
              title: PartCatConfig.customReport.title,
              accessor: PartCatConfig.customReport.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

          "webOutput": {
              title: PartCatConfig.webOutput.title,
              accessor: PartCatConfig.webOutput.accessor,
              reverse: ReverseOptions.general.checkbox,
          },


      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.installation,
      sections: {
          "hardware": {
              title: PartCatConfig.hardware.title,
              accessor: PartCatConfig.hardware.accessor,
              reverse: ReverseOptions.partcat_options.lengthType

          },
          "hardwareHosting": {
              title: PartCatConfig.hardwareHosting.title,
              accessor: PartCatConfig.hardwareHosting.accessor,
              reverse: ReverseOptions.partcat_options.hardwareHosting
          },
          "multiUser": {
              title: PartCatConfig.multiUser.title,
              accessor: PartCatConfig.multiUser.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "windowsCertified": {
              title: PartCatConfig.windowsCertified.title,
              accessor: PartCatConfig.windowsCertified.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "activeFirewall": {
              title: PartCatConfig.activeFirewall.title,
              accessor: PartCatConfig.activeFirewall.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "microsoftTeam": {
              title: PartCatConfig.microsoftTeam.title,
              accessor: PartCatConfig.microsoftTeam.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.database,
      sections: {
          "dbType": {
              title: PartCatConfig.dbType.title,
              accessor: PartCatConfig.dbType.accessor,
              reverse: ReverseOptions.partcat_options.dbType
          },
          "dbVersion": {
              title: PartCatConfig.dbVersion.title,
              accessor: PartCatConfig.dbVersion.accessor,
          },

      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.subscriptions,
      sections: {
          "oeData": {
              title: PartCatConfig.oeData.title,
              accessor: PartCatConfig.oeData.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "vioData": {
              title: PartCatConfig.vioData.title,
              accessor: PartCatConfig.vioData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.napa,
      sections: {
          "acesToNapa": {
              title: PartCatConfig.acesToNapa.title,
              accessor: PartCatConfig.acesToNapa.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "napaConversion": {
              title: PartCatConfig.napaConversion.title,
              accessor: PartCatConfig.napaConversion.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },
  {
      headerTitle: PartCatConfig.headerTitles.tecdoc,
      sections: {
          "acesToTecDoc": {
              title: PartCatConfig.acesToTecDoc.title,
              accessor: PartCatConfig.acesToTecDoc.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "tecdocConversion": {
              title: PartCatConfig.tecdocConversion.title,
              accessor: PartCatConfig.tecdocConversion.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "deliveryDate": {
              title: PartCatConfig.deliveryDate.title,
              accessor: PartCatConfig.deliveryDate.accessor,
          },
      }
  },
  {
      headerTitle: "",
      sections: {
          "additionalInfo": {
              title: PartCatConfig.additionalInfo.title,
              accessor: PartCatConfig.additionalInfo.accessor,
          }
      }
  },


  ],
  DM: [{
      headerTitle: DMConfig.headerTitles.dataManagement,
      sections: {
        "active": {
            title: DMConfig.active.title,
            accessor: DMConfig.active.accessor,
            input: "checkbox",
            reverse: ReverseOptions.general.checkbox,
        },
          "contractLength": {
              title: DMConfig.contractLength.title,
              accessor: DMConfig.contractLength.accessor,
          },
          "lengthType": {
              title: DMConfig.lengthType.title,
              accessor: DMConfig.lengthType.accessor,
              reverse: ReverseOptions.dm_options.lengthType

          },
          "startDate": {
              title: DMConfig.startDate.title,
              accessor: DMConfig.startDate.accessor,
          },
          "endDate": {
              title: DMConfig.endDate.title,
              accessor: DMConfig.endDate.accessor,
          },
      }
  },
  {
      headerTitle: DMConfig.headerTitles.application,
      sections: {
          "existingAces": {
              title: DMConfig.existingAces.title,
              accessor: DMConfig.existingAces.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "acesDeliveryType": {
              title: DMConfig.acesDeliveryType.title,
              accessor: DMConfig.acesDeliveryType.accessor,
              reverse: ReverseOptions.dm_options.acesDeliveryType,

          },
          "buildDataFromOe": {
              title: DMConfig.buildDataFromOe.title,
              accessor: DMConfig.buildDataFromOe.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "numberOfOePart": {
              title: DMConfig.numberOfOePart.title,
              accessor: DMConfig.numberOfOePart.accessor,
          },
          "researchType": {
              title: DMConfig.researchType.title,
              accessor: DMConfig.researchType.accessor,
              reverse: ReverseOptions.dm_options.researchType,
          },
          "crossReferenceExisting": {
              title: DMConfig.crossReferenceExisting.title,
              accessor: DMConfig.crossReferenceExisting.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "heavyDuty": {
              title: DMConfig.heavyDuty.title,
              accessor: DMConfig.heavyDuty.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "customData": {
              title: DMConfig.customData.title,
              accessor: DMConfig.customData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },

  {
      headerTitle: DMConfig.headerTitles.pies,
      sections: {
          "piesExistingPies": {
              title: DMConfig.piesExistingPies.title,
              accessor: DMConfig.piesExistingPies.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "piesDeliveryType": {
              title: DMConfig.piesDeliveryType.title,
              accessor: DMConfig.piesDeliveryType.accessor,
              reverse: ReverseOptions.dm_options.piesDeliveryType,
          },
          "piesRebranding": {
              title: DMConfig.piesRebranding.title,
              accessor: DMConfig.piesRebranding.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },

  {
      headerTitle: DMConfig.headerTitles.padb,
      sections: {
          "padbExistingPies": {
              title: DMConfig.padbExistingPies.title,
              accessor: DMConfig.padbExistingPies.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "padbDeliveryType": {
              title: DMConfig.padbDeliveryType.title,
              accessor: DMConfig.padbDeliveryType.accessor,
              reverse: ReverseOptions.dm_options.padbDeliveryType,
          },
      }
  },

  {
      headerTitle: DMConfig.headerTitles.digital,
      sections: {
          "physicalImages": {
              title: DMConfig.physicalImages.title,
              accessor: DMConfig.physicalImages.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "piesDigitalAssetImages": {
              title: DMConfig.piesDigitalAssetImages.title,
              accessor: DMConfig.piesDigitalAssetImages.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "physical360Images": {
              title: DMConfig.physical360Images.title,
              accessor: DMConfig.physical360Images.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "piesDigitalAsset360Images": {
              title: DMConfig.piesDigitalAsset360Images.title,
              reverse: ReverseOptions.general.checkbox,
          },
      }

  },
  {
      headerTitle: DMConfig.headerTitles.maintenance,
      sections: {
          "updateFrequency": {
              title: DMConfig.updateFrequency.title,
              accessor: DMConfig.updateFrequency.accessor,
              reverse: ReverseOptions.dm_options.updateFrequency,

          },
          "partUpdateQuota": {
              title: DMConfig.partUpdateQuota.title,
              accessor: DMConfig.partUpdateQuota.accessor,
          },
      }
  },
  {
      headerTitle: DMConfig.headerTitles.tecdoc,
      sections: {
          "tecdocActive": {
              title: DMConfig.tecdocActive.title,
              accessor: DMConfig.tecdocActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "tecdocOeCrossReference": {
              title: DMConfig.tecdocOeCrossReference.title,
              accessor: DMConfig.tecdocOeCrossReference.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "tecdocApplicationData": {
              title: DMConfig.tecdocApplicationData.title,
              accessor: DMConfig.tecdocApplicationData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "tecdocPiesData": {
              title: DMConfig.tecdocPiesData.title,
              accessor: DMConfig.tecdocPiesData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "tecdocPadbData": {
              title: DMConfig.tecdocPadbData.title,
              accessor: DMConfig.tecdocPadbData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "tecdocDigitalAssetData": {
              title: DMConfig.tecdocDigitalAssetData.title,
              accessor: DMConfig.tecdocDigitalAssetData.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },
  {
      headerTitle: DMConfig.headerTitles.oe,
      sections: {
          "oeDataActive": {
              title: DMConfig.oeDataActive.title,
              accessor: DMConfig.oeDataActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "oeDataSource": {
              title: DMConfig.oeDataSource.title,
              accessor: DMConfig.oeDataSource.accessor,
              reverse: ReverseOptions.dm_options.oeDataSource,
          },
          "oeDataCarryOver": {
              title: DMConfig.oeDataCarryOver.title,
              accessor: DMConfig.oeDataCarryOver.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "oeDataShoppingList": {
              title: DMConfig.oeDataShoppingList.title,
              accessor: DMConfig.oeDataShoppingList.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },
  {
      headerTitle: DMConfig.headerTitles.vio,
      sections: {
          "vioDataActive": {
              title: DMConfig.vioDataActive.title,
              accessor: DMConfig.vioDataActive.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "vioDataRegion": {
              title: DMConfig.vioDataRegion.title,
              accessor: DMConfig.vioDataRegion.accessor,
              reverse: ReverseOptions.dm_options.vioDataRegion,
          },
          "vioDataLevel": {
              title: DMConfig.vioDataLevel.title,
              accessor: DMConfig.vioDataLevel.accessor,
              reverse: ReverseOptions.dm_options.vioDataLevel,
          },

      }
  },

  {
      headerTitle: DMConfig.headerTitles.napa,
      sections: {
          "napaDataActive": {
              title: DMConfig.napaDataActive.title,
              accessor: DMConfig.napaDataActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "napaDataConversion": {
              title: DMConfig.napaDataConversion.title,
              accessor: DMConfig.napaDataConversion.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },


  {
      headerTitle: DMConfig.headerTitles.opticat,
      sections: {
          "opticatSubscription": {
              title: DMConfig.opticatSubscription.title,
              accessor: DMConfig.opticatSubscription.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },

  {
      headerTitle: DMConfig.headerTitles.objective,
      sections: {
          "objectiveShortTerm": {
              title: DMConfig.objectiveShortTerm.title,
              accessor: DMConfig.objectiveShortTerm.accessor,
          },
          "objectiveMidTerm": {
              title: DMConfig.objectiveMidTerm.title,
              accessor: DMConfig.objectiveMidTerm.accessor,

          },
          "objectiveLongTerm": {
              title: DMConfig.objectiveLongTerm.title,
              accessor: DMConfig.objectiveLongTerm.accessor,

          },
          "objectivePromiseToCustomer": {
              title: DMConfig.objectivePromiseToCustomer.title,
              accessor: DMConfig.objectivePromiseToCustomer.accessor,

          },
          "objectiveTimeline": {
              title: DMConfig.objectiveTimeline.title,
              accessor: DMConfig.objectiveTimeline.accessor,
          },
      }
  },
  {
      headerTitle: DMConfig.headerTitles.oeProducts,
      gridSize: 4,
      sections: {
          "oeDataProductLines": {

              accessor: DMConfig.oeDataProductLines.accessor,
              reverse: createProductLineTable
          },
      }
  }
  ],
  VIO: [{
      headerTitle: VIOConfig.headerTitles.contact,
      sections: {
          "sameAsMainContact": {
              title: VIOConfig.sameAsMainContact.title,
              accessor: VIOConfig.sameAsMainContact.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "name": {
              title: VIOConfig.name.title,
              accessor: VIOConfig.name.accessor,

          },
          "email": {
              title: VIOConfig.email.title,
              accessor: VIOConfig.email.accessor,

          },
          "phone": {
              title: VIOConfig.phone.title,
              accessor: VIOConfig.phone.accessor,

          },
      }
  },
  {
      headerTitle: VIOConfig.headerTitles.contract,
      sections: {
          "contractDate": {
              title: VIOConfig.contractDate.title,
              accessor: VIOConfig.contractDate.accessor,


          },
          "contractEndDate": {
              title: VIOConfig.contractEndDate.title,
              accessor: VIOConfig.contractEndDate.accessor,

          },
          "contractFrequency": {
              title: VIOConfig.contractFrequency.title,
              accessor: VIOConfig.contractFrequency.accessor,
              reverse: ReverseOptions.vio_options.contractFrequency,


          },
          "contractActiveForDelivery": {
              title: VIOConfig.contractActiveForDelivery.title,
              accessor: VIOConfig.contractActiveForDelivery.accessor,

          },
      }
  },
  {
      headerTitle: VIOConfig.headerTitles.usaLightDuty,
      sections: {
          "usaLightDutyActive": {
              title: VIOConfig.usaLightDutyActive.title,
              accessor: VIOConfig.usaLightDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

          "usaLightDutyNational": {
              title: VIOConfig.usaLightDutyNational.title,
              accessor: VIOConfig.usaLightDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaLightDutyState": {
              title: VIOConfig.usaLightDutyState.title,
              accessor: VIOConfig.usaLightDutyState.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaLightDutyCounty": {
              title: VIOConfig.usaLightDutyCounty.title,
              accessor: VIOConfig.usaLightDutyCounty.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaLightDutyZip": {
            title: VIOConfig.usaLightDutyZip.title,
            accessor: VIOConfig.usaLightDutyZip.accessor,
            reverse: ReverseOptions.general.checkbox,

        },
      }
  },
  {
      headerTitle: VIOConfig.headerTitles.usaHeavyDuty,
      sections: {
          "usaHeavyDutyActive": {
              title: VIOConfig.usaHeavyDutyActive.title,
              accessor: VIOConfig.usaHeavyDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "usaHeavyDutyNational": {
              title: VIOConfig.usaHeavyDutyNational.title,
              accessor: VIOConfig.usaHeavyDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaHeavyDutyState": {
              title: VIOConfig.usaHeavyDutyState.title,
              accessor: VIOConfig.usaHeavyDutyState.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaHeavyDutyCounty": {
              title: VIOConfig.usaHeavyDutyCounty.title,
              accessor: VIOConfig.usaHeavyDutyCounty.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "usaHeavyDutyZip": {
            title: VIOConfig.usaHeavyDutyZip.title,
            accessor: VIOConfig.usaHeavyDutyZip.accessor,
            reverse: ReverseOptions.general.checkbox,

        },
      },

  },

  {
      headerTitle: VIOConfig.headerTitles.canLightDuty,
      sections: {
          "canLightDutyActive": {
              title: VIOConfig.canLightDutyActive.title,
              accessor: VIOConfig.canLightDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "canLightDutyNational": {
              title: VIOConfig.canLightDutyNational.title,
              accessor: VIOConfig.canLightDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

          "canLightDutyProvince": {
              title: VIOConfig.canLightDutyProvince.title,
              accessor: VIOConfig.canLightDutyProvince.accessor,
              reverse: ReverseOptions.general.checkbox,

          },
          "canLightDutyMunicipal": {
            title: VIOConfig.canLightDutyMunicipal.title,
            accessor: VIOConfig.canLightDutyMunicipal.accessor,
            reverse: ReverseOptions.general.checkbox,

        },
        "canLightDutyPostalCode": {
            title: VIOConfig.canLightDutyPostalCode.title,
            accessor: VIOConfig.canLightDutyPostalCode.accessor,
            reverse: ReverseOptions.general.checkbox,

        },

      },

  },
  {
      headerTitle: VIOConfig.headerTitles.canHeavyDuty,
      sections: {
          "canHeavyDutyActive": {
              title: VIOConfig.canHeavyDutyActive.title,
              accessor: VIOConfig.canHeavyDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "canHeavyDutyNational": {
              title: VIOConfig.canHeavyDutyNational.title,
              accessor: VIOConfig.canHeavyDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "canHeavyDutyProvince": {
              title: VIOConfig.canHeavyDutyProvince.title,
              accessor: VIOConfig.canHeavyDutyProvince.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "canHeavyDutyMunicipal": {
            title: VIOConfig.canHeavyDutyMunicipal.title,
            accessor: VIOConfig.canHeavyDutyMunicipal.accessor,
            reverse: ReverseOptions.general.checkbox,
        },
        "canHeavyDutyPostalCode": {
            title: VIOConfig.canHeavyDutyPostalCode.title,
            accessor: VIOConfig.canHeavyDutyPostalCode.accessor,
            reverse: ReverseOptions.general.checkbox,
        },
      }
  },
  {
      headerTitle: VIOConfig.headerTitles.mexLightDuty,
      sections: {
          "mexLightDutyActive": {
              title: VIOConfig.mexLightDutyActive.title,
              accessor: VIOConfig.mexLightDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "mexLightDutyNational": {
              title: VIOConfig.mexLightDutyNational.title,
              accessor: VIOConfig.mexLightDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "mexLightDutyState": {
              title: VIOConfig.mexLightDutyState.title,
              accessor: VIOConfig.mexLightDutyState.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "mexLightDutyMunicipal": {
              title: VIOConfig.mexLightDutyMunicipal.title,
              accessor: VIOConfig.mexLightDutyMunicipal.accessor,
              reverse: ReverseOptions.general.checkbox,
          },

      }
  },
  {
      headerTitle: VIOConfig.headerTitles.mexHeavyDuty,
      sections: {
          "mexHeavyDutyActive": {
              title: VIOConfig.mexHeavyDutyActive.title,
              accessor: VIOConfig.mexHeavyDutyActive.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "mexHeavyDutyNational": {
              title: VIOConfig.mexHeavyDutyNational.title,
              accessor: VIOConfig.mexHeavyDutyNational.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "mexHeavyDutyState": {
              title: VIOConfig.mexHeavyDutyState.title,
              accessor: VIOConfig.mexHeavyDutyState.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      },
  },
  {
    headerTitle: VIOConfig.headerTitles.additionalInfo,
    sections: {
        "additionalInfo": {
            title: VIOConfig.additionalInfo.title,
            accessor: VIOConfig.additionalInfo.accessor,
        }
    }
  },

  ],
  OE: [{
      headerTitle: OEConfig.headerTitles.options,

      sections: {
          "active": {
              title: OEConfig.active.title,
              accessor: OEConfig.active.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
          "frequency": {
              title: OEConfig.frequency.title,
              accessor: OEConfig.frequency.accessor,
              reverse: ReverseOptions.oe_options.frequency,

          },
          "partCatVersion": {
              title: OEConfig.partCatVersion.title,
              accessor: OEConfig.partCatVersion.accessor,

          },
          "ftpUsername": {
              title: OEConfig.ftpUsername.title,
              accessor: OEConfig.ftpUsername.accessor,


          },
          "kTypeDelivery": {
              title: OEConfig.kTypeDelivery.title,
              accessor: OEConfig.kTypeDelivery.accessor,
              reverse: ReverseOptions.general.checkbox,
          },
      }
  },
  {
      headerTitle: OEConfig.headerTitles.info,
      sections: {
          "formats": {
              title: OEConfig.formats.title,
              accessor: OEConfig.formats.accessor,

          },
          "dbType": {
              title: OEConfig.dbType.title,
              accessor: OEConfig.dbType.accessor,
              reverse: ReverseOptions.oe_options.dbType,

          },
          "dbVersion": {
              title: OEConfig.dbVersion.title,
              accessor: OEConfig.dbVersion.accessor,

          },

      }
  },
  {
      headerTitle: OEConfig.headerTitles.products,
      gridSize: 6,
      sections: {
          "productLines": {
              title: "",
              accessor: OEConfig.productLines.accessor,
              reverse: createProductLineTable

          }
      }
  },
  {
      headerTitle: OEConfig.headerTitles.additionalInfo,
      sections: {
          "additionalInfo": {
              title: OEConfig.additionalInfo.title,
              accessor: OEConfig.additionalInfo.accessor,

          }
      }
  },
  ],
  PCO: [{
      headerTitle: PCOConfig.headerTitles.options,
      sections: {
          "active": {
              title: PCOConfig.active.title,
              accessor: PCOConfig.active.accessor,
              input: "checkbox",
              reverse: ReverseOptions.general.checkbox,
          },
          "customerId": {
              title: PCOConfig.customerId.title,
              accessor: PCOConfig.customerId.accessor,

          },
          "hasACES": {
              title: PCOConfig.hasACES.title,
              accessor: PCOConfig.hasACES.accessor,
          },
          "vcdbTypes": {
              title: PCOConfig.vcdbTypes.title,
              accessor: PCOConfig.vcdbTypes.accessor,
          },
          "hasPADB": {
              title: PCOConfig.hasPADB.title,
              accessor: PCOConfig.hasPADB.accessor,
          },
      }
  },{
      headerTitle: PCOConfig.headerTitles.setupInfo,
      sections: {
          "adminAccount": {
              title: PCOConfig.adminAccount.title,
              accessor: PCOConfig.adminAccount.accessor,
          },
          "additionalAccounts": {
              title: PCOConfig.additionalAccounts.title,
              accessor: PCOConfig.additionalAccounts.accessor,

          },
          "brandAAIA": {
              title: PCOConfig.brandAAIA.title,
              accessor: PCOConfig.brandAAIA.accessor,
          },
          "expectedNumberOfParts": {
              title: PCOConfig.expectedNumberOfParts.title,
              accessor: PCOConfig.expectedNumberOfParts.accessor,
          },
          "onboardingRequired": {
              title: PCOConfig.onboardingRequired.title,
              accessor: PCOConfig.onboardingRequired.accessor,
              reverse: ReverseOptions.pco_options.onboardingRequired,
          },
      }
  },{
      headerTitle: PCOConfig.headerTitles.dataToLoad,
      sections: {
          "hasACESXML": {
              title: PCOConfig.hasACESXML.title,
              accessor: PCOConfig.hasACESXML.accessor,
          },
          "hasACESSpreadsheet": {
              title: PCOConfig.hasACESSpreadsheet.title,
              accessor: PCOConfig.hasACESSpreadsheet.accessor,

          },
          "hasPIESXML": {
              title: PCOConfig.hasPIESXML.title,
              accessor: PCOConfig.hasPIESXML.accessor,
          },
          "hasPIESSpreadsheet": {
              title: PCOConfig.hasPIESSpreadsheet.title,
              accessor: PCOConfig.hasPIESSpreadsheet.accessor,
          },
          "digitalAssets": {
              title: PCOConfig.digitalAssets.title,
              accessor: PCOConfig.digitalAssets.accessor,
          },
          "conversionNeeded": {
              title: PCOConfig.conversionNeeded.title,
              accessor: PCOConfig.conversionNeeded.accessor,
          },
      }
  },
  {
      headerTitle: "Additional Information",
      sections: {
          "additionalInfo": {
              title: PCOConfig.additionalInfo.title,
              accessor: PCOConfig.additionalInfo.accessor,
          }
      }
  },
  ]
}