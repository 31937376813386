import React from 'react';
import PropTypes from 'prop-types';
import { ActionBarHeight, FooterHeight, TabsHeight } from '../utils';

const TabPanel = ({ children, value, index, style, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`response-tabpanel-${index}`}
            aria-labelledby={`response-tab-${index}`}
            style={{ height: `calc(100% - (${ActionBarHeight}px + ${TabsHeight}px + ${FooterHeight}px))`,overflowY: "scroll", maxHeight: `calc(100% - 128px)`, ...style }}
            {...other}

        >
            {value === index && (
                <div style={{ height: "inherit" }}>
                    {children}
                </div>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

export default TabPanel