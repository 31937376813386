import { DateTime } from 'luxon';

export const convertISOtoLocal = (dateISO, zone = 'utc', outputformat = 'yyyy-LL-dd hh:mm:ss a') => {
    const dt = DateTime.fromISO(dateISO, {zone}).toLocal();
    return dt.toFormat(outputformat);
};

export const convertObjectFieldISOtoLocal = (key = 'date') => (obj) => {
    const {[key]:value, ...newObj} = obj;
    newObj[key] = convertISOtoLocal(value);
    return newObj;
};