import { Box, IconButton, Typography } from "@mui/material";
import {
    Types,
    requestStatusDescription,
    shortRequestStatusDescription,
    requestTypesDescription
} from "../constants/DefaultOptions";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import { getEnumFlags } from '../services/ConfigurationService';
import React from "react";

const FooterHeight = 35;
const ActionBarHeight = 48;
const TabsHeight = 48;

const a11yProps = (index) => {
    return {
        id: `response-tab-${index}`,
        'aria-controls': `response-tabpanel-${index}`,
    }
};

const reverseLengths = (value) => {
    switch (value) {
        case 1:
            return 'Years';
        case 2:
            return 'Months';
        case 3:
            return 'Weeks';
        default:
            return '';
    }
};

const reverseHardware = (value) => {
    switch (value) {
        case 1:
            return 'Server';
        case 2:
            return 'SinglePC';
        default:
            return '';
    }
};

const reverseHosting = (value) => {
    switch (value) {
        case 1:
            return 'Premise';
        case 2:
            return 'Cloud';
        default:
            return '';
    }
};

const reverseDBTypes = (value) => {
    switch (value) {
        case 1:
            return 'MySQL';
        case 2:
            return 'MSSQL';
        default:
            return '';
    }
};


const reverseContractFrequency = (value) => {
    switch (value) {
        case 1:
            return 'Yearly';
        case 2:
            return 'Quarterly';
        default:
            return '';
    }
}

const reverseFrequency = (value) => {
    switch (value) {
        case 1:
            return 'Monthly';
        case 2:
            return 'Quarterly';
        case 3:
            return 'Yearly';
        default:
            return '';
    }
}
const reverseOeFrequency = (value) => {
    switch (value) {
        case 1:
            return 'OneTime';
        case 2:
            return 'Weekly';
        case 3:
            return 'BiWeekly';
        case 4:
            return 'Monthly';
        case 5:
            return 'Quarterly';
        case 6:
            return 'Yearly';
        default:
            return '';
    }
}

const reverseOnboardingRequired = (value) => {

    switch (value) {
        case 1:
            return 'Yes';
        case 2:
            return 'Basic';
        case 3:
            return 'No';
        default:
            return '';
    }
}

const reverseProductGroupsSetup = (value) => {

    switch (value) {
        case 1:
            return 'Auto Care';
        case 2:
            return 'Custom';
        default:
            return '';
    }
}

const reverseLifeCycleConfigurationType = (value) => {

    switch (value) {
        case 1:
            return 'Auto Care';
        case 2:
            return 'Custom';
        default:
            return '';
    }
}

const reverseAcesTemplates = (value) => {

    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'PartCat-like';
        case 3:
            return 'Custom';
        default:
            return '';
    }
}

const reversePiesTemplates = (value) => {

    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'PIES 7.2';
        case 3:
            return 'Custom';
        default:
            return '';
    }
}


const reverseAdditionalParts = (value) => {

    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'PartCat-like';
        case 3:
            return 'Custom';
        default:
            return '';
    }
}

const reverseAssetTypeConfig = (value) => {

    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'PartCat-like';
        case 3:
            return 'Custom';
        default:
            return '';
    }
}

const reverseSuppliersToCreate = (value) => {

    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'List-Provided';
        default:
            return '';
    }
}

const reverseTypes = (originalRow, props) => {
    const flagTypes = getEnumFlags(Types);

    return flagTypes.map((value, key) => {
        switch (originalRow.type & value) {
            case Types.PartCat: // 1
                return <Chip key="PartCat" label="PartCat" size="small" sx={{ background: "#40bcd8", color: "#fff", marginRight: '4px', '.MuiChip-icon': { color: "#fff" } }} {...props} />;
            case Types.DM: // 2
                return <Chip key="DM" label="DM" size="small" sx={{ background: "#ff0000", color: "#fff", marginRight: '4px', '.MuiChip-icon': { color: "#fff" } }}  {...props} />;
            case Types.VIO: // 4
                return <Chip key="VIO" label="VIO" size="small" sx={{ background: "#36454F", color: "#fff", marginRight: '4px', '.MuiChip-icon': { color: "#fff" } }} {...props} />;
            case Types.OE: // 8
                return <Chip key="OE" label="OE" size="small" sx={{ background: "#4B0082", color: "#fff", marginRight: '4px', '.MuiChip-icon': { color: "#fff" } }} {...props} />;
            case Types.PCO: // 16
                return <Chip key="PCO" label="PCO" size="small" sx={{ background: "#ff562b", color: "#fff", marginRight: '4px', '.MuiChip-icon': { color: "#fff" } }} {...props} />;
            default:
                return '';
        }
    })
}

const reverseTypesCSV = (originalRow, props) => {
    const flagTypes = getEnumFlags(Types);

    var flags =  flagTypes.map((value, key) => {
        switch (originalRow.type & value) {
            case Types.PartCat: // 1
                return "PartCat";
            case Types.DM: // 2
                return "DM";
            case Types.VIO: // 4
                return "VIO";
            case Types.OE: // 8
                return "OE";
            case Types.PCO: // 16
                return "PCO";
            default:
                return '';
        }
    })

    return flags ? flags.filter((f) => f != "").join(", ") : "";
}

export const getOptionByType = (customer, type) => {
    switch(type)
    {
        case "DM":
            return customer.options.dm_options;
        case "OE":
            return customer.options.oe_options;
        case "PartCat":
            return customer.options.partcat_options;
        case "PCO":
            return customer.options.pco_options;
        case "VIO":
            return customer.options.vio_options;
        default:
            return false;
    }

}
export const reverseOECustomerType = (value) => {
    switch (value) {
        case 1:
            return 'Full';
        case 2:
            return 'On Demand';
        default:
            return '';
    }
};

const createAccessorStatus = (originalRow, rowIndex) => {
    const isCustomerHidden = originalRow?.options?.isCustomerHidden ?? false;
    let statusChip = null;
    switch (originalRow.status) {
        case 1:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#ff0000", color: "#fff", '.MuiChip-icon': { color: "#fff" } }} />;
            break;
        case 2:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#ffff00", color: "#707171", '.MuiChip-icon': { color: "#707171" } }} />;
            break;
        case 3:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#0C6", color: "#fff", '.MuiChip-icon': { color: "#fff" } }} />;
            break;
        case 4:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#ff8000", color: "#fff", '.MuiChip-icon': { color: "#fff" } }} />;
            break;
        case 5:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#ffff00", color: "#707171", '.MuiChip-icon': { color: "#707171" } }} />;
            break;
        case 6:
            statusChip = <Chip label={requestStatusDescription[originalRow.status]} size="small" sx={{ background: "#ffff00", color: "#707171", '.MuiChip-icon': { color: "#707171" } }} />;
            break;
        default:
            statusChip = <Chip label={requestStatusDescription[0]} />
            break;
    }

    if (isCustomerHidden) {
        return (
            <React.Fragment>
                {
                    statusChip
                }
                <Chip label="HIDDEN" size="small" sx={{ background: "#ff0000", color: "#fff", '.MuiChip-icon': { color: "#707171" } }} />
            </React.Fragment>
        );
    }
    
    return statusChip;
};  

const reverseRequestType = (request) => {
    switch (request.type) {
        case 1:
            return requestTypesDescription[request.type];
        case 2:
            return requestTypesDescription[request.type];
        case 3:
            return requestTypesDescription[request.type];
        default:
            return '';
    }
};

const reverseRequestStatus = (request) => {
    switch (request.status) {
        case 1:
            return shortRequestStatusDescription[request.status];
        case 2:
            return shortRequestStatusDescription[request.status];
        case 3:
            return shortRequestStatusDescription[request.status];
        case 4:
            return shortRequestStatusDescription[request.status];
        case 5:
            return shortRequestStatusDescription[request.status];
        case 6:
            return shortRequestStatusDescription[request.status];
        default:
            return shortRequestStatusDescription[0];
    }
};

const reverseResearchType = (value) => {
    switch (value) {
        case 1:
            return '95+';
        case 2:
            return 'Pre 95';
        default:
            return '';
    }
}

const reverseOeDataSource = (value) => {
    switch (value) {
        case 1:
            return 'JNP';
        case 2:
            return 'Motor';
        default:
            return '';
    }
}
const reversePCOVCDBTypes = (value) => {
    switch (value) {
        case 1:
            return 'LD';
        case 2:
            return 'MDHD';
        case 3:
            return 'PS';
        default:
            return '';
    }
}


const reverseVioDataLevel = (value) => {
    switch (value) {
        case 1:
            return 'National';
        case 2:
            return 'State';
        default:
            return '';
    }
}

const reverseVioDataRegion = (value) => {
    switch (value) {
        case 1:
            return 'USA';
        case 2:
            return 'CAN';
        case 3:
            return 'MEX'
        default:
            return '';
    }
}
const reverseDeliveryType = (value) => {
    switch (value) {
        case 1:
            return 'XML';
        case 2:
            return 'Excel';
        default:
            return '';
    }
}

const reverseCheckbox = (value) => {
    if(value === true)
        return 'Yes'
    else
        return 'No'
}

const createFilterDescription = (descriptions, getIndex) => (rows, fieldName, fragment) => {
    const upperdesc = descriptions.map((d) => d.toUpperCase());
    const upperfrag = fragment.toUpperCase();
    return rows.filter((r) => {
        const index = getIndex(r.original);
        return index && upperdesc[index] && upperdesc[index].includes(upperfrag);
    })
};

const HandleContactsActionsCell = (
    {
        row: { index },
    },
    contacts,
    delIds,
    updateContacts,
    setStatus) => {
    const handleDelete = () => updateContacts(contacts.filter((val, ind) => ind !== index));

    const handleEdit = () => setStatus({ open: true, contactToEdit: contacts[index], index: index });

    return (
        <Box>
            <IconButton onClick={handleEdit} disabled={delIds.includes(contacts[index].id)}> <EditIcon fontSize="inherit" /></IconButton>
            <IconButton onClick={handleDelete} disabled={delIds.includes(contacts[index].id)}> <DeleteIcon fontSize="inherit" /></IconButton>
        </Box>
    )
}

const createContactAccessor = (row, data, accessor) => {

    if (Object.keys(data.modContacts).includes(row.id.toString()) && row[accessor] !== data.modContacts[row.id][accessor]) {
        return <div>
            {`${row[accessor]}   `}
            <Typography variant="string" style={{ color: 'red' }}>{`previously: ${data.modContacts[row.id][accessor]}`}</Typography>
        </div>
    }
    if (data.addIds.includes(row.id)) {
        return <div style={{ color: 'green' }}>{row[accessor]}</div>
    }
    if (data.delIds.includes(row.id)) {
        return <div style={{ color: 'red' }}>{row[accessor]}</div>
    }
    return <div>{row[accessor]}</div>;
}

const HandleProductsActionsCell = (
    {
        row: { index },
    },
    products,
    updateStatus,
    deleteProduct) => {

    const handleEdit = () => updateStatus(products[index]);
    const handleDelete = () => deleteProduct(products[index]);
    const handleCopy = () => updateStatus({ ...products[index], productLineId: -1 });

    return (
        <Box>
            <IconButton onClick={handleEdit}> <EditIcon fontSize="inherit" /></IconButton>
            <IconButton onClick={handleDelete}> <DeleteIcon fontSize="inherit" /></IconButton>
            <IconButton onClick={handleCopy}><ContentCopyIcon fontSize="inherit" /></IconButton>
        </Box>
    )
}

export const downloadFile = (blob, fileName) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
        new Blob([blob.data], { type: 'application/zip' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        fileName,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}


const ReverseOptions =
{
    partcat_options: {
        lengthType: reverseLengths,
        licenseType: reverseLengths,
        hardware: reverseHardware,
        hardwareHosting: reverseHosting,
        dbType: reverseDBTypes,
    },

    dm_options: {
        lengthType: reverseLengths,
        acesDeliveryType: reverseDeliveryType,
        researchType: reverseResearchType,
        piesDeliveryType: reverseDeliveryType,
        padbDeliveryType: reverseDeliveryType,
        updateFrequency: reverseFrequency,
        oeDataSource: reverseOeDataSource,
        vioDataRegion: reverseVioDataRegion,
        vioDataLevel: reverseVioDataLevel,
    },
    vio_options: {
        contractFrequency: reverseContractFrequency,

    },
    oe_options: {

        frequency: reverseFrequency,
        dbType: reverseDBTypes,

    },
    pco_options: {
        onboardingRequired: reverseOnboardingRequired,
        productGroupsSetup : reverseProductGroupsSetup,
        lifeCycleConfigurationType: reverseLifeCycleConfigurationType,
        acesTemplates: reverseAcesTemplates,
        piesTemplates : reversePiesTemplates,
        additionalParts : reverseAdditionalParts,
        assetTypeConfig : reverseAssetTypeConfig,
        suppliersToCreate : reverseSuppliersToCreate,

    },
    general: {
        checkbox : reverseCheckbox,
    }
}



export {
    a11yProps,
    reverseLengths,
    reverseHardware,
    reverseHosting,
    reverseDBTypes,
    reverseOeFrequency,
    createAccessorStatus,
    reverseTypes,
    reverseTypesCSV,
    reverseRequestType,
    reverseRequestStatus,
    HandleContactsActionsCell,
    FooterHeight,
    ActionBarHeight,
    TabsHeight,
    createContactAccessor,
    HandleProductsActionsCell,
    createFilterDescription,
    reversePCOVCDBTypes,
    ReverseOptions,
}