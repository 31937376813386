import _ from "lodash";

const LabelEditCustomer = "Edit Customer";
const LabelTerminateCustomer = "Terminate Customer";
const LabelAbandonRequest = "Abandon Request";
const LabelRejectChanges = "Reject Changes";

export const configureActionBar = (newActions, actionInfos, setActionInfos) => {
    if (!compareActions(newActions, actionInfos?.actions))
    {
        setActionInfos({...actionInfos, actions: newActions, actionClicked: null});
    }
}

export const executeAction = (onAction, actionInfos, setActionInfos) => {
    onAction();
    setActionInfos({...actionInfos, actionClicked: null});
}

export const configureDisabled = (name, disabled, actionInfos, setActionInfos) => {
    const actionIndex = actionInfos.actions.findIndex(x => x.name === name);

    if (actionIndex > -1)
    {
        const currentDisableStatus = actionInfos.actions[actionIndex]?.disabled;

        if (currentDisableStatus !== disabled)
        {
            const newActions = [...actionInfos.actions];
            newActions[actionIndex].disabled = disabled;
            setActionInfos({
                ...actionInfos,
                actions: newActions,
                actionClicked: null,
            })
        }
    }
}

export const compareActions = (actions, otherActions) => {
    return _.isEqual(
        _.map(actions, (obj) => _.pick(obj, ['name', 'label'])),
        _.map(otherActions, (obj) => _.pick(obj, ['name', 'label']))
      );
}

export const buildCustomerActions = (isDisplay, isManager) => {
    const managerActions = [
        {
            label: "Approve Request",
            name: "approve",
        },
        {
            label: "Reject Request",
            name: "reject",
        }
    ];

    const displayActions = [
        {
            label: "Back",
            name: "back",
        },
        {
            label: LabelEditCustomer,
            name: "edit",
        },
        {
            label: LabelTerminateCustomer,
            name: "terminate",
        },
        {
            label: LabelRejectChanges,
            name: "rejectChanges",
        },
        {
            label: LabelAbandonRequest,
            name: "abandon",
        },
    ];

    const editActions = [
        {
            label: "Cancel",
            name: "cancel",
        },
        {
            label: "Save (Incomplete)",
            name: "save-incomplete",
        },
        {
            label: "Save Complete & Submit",
            name: "save-submit",
        }
    ];

    return isDisplay ? [...displayActions, ...(isManager ? managerActions : [])] : editActions;
}