import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomerOptions from '../../../components/customer-options/CustomerOptions';
import { DefaultCustomer, Status, Types, DefaultTitle } from '../../../constants/DefaultOptions';
import {
    getCustomer,
    saveCustomer,
    approveCustomer,
    rejectCustomer,
    abandonCustomer,
    terminateCustomer,
    deleteCustomer,
    rejectChangesCustomer,
} from '../../../services/ApiService';
import { useSnackbar } from 'notistack';
import { Permissions } from '../../../models/user-profile';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { reverseTypes, getOptionByType } from "../../../utils";
import Box from '@mui/material/Box';


const SAVE_MESSAGE = 'Request updated successfully.';
const SUBMIT_MESSAGE = 'Request submitted successfully.';
const APPROVE_CHANGES_MESSAGE = 'Customer saved successfully.';
const APPROVE_TERMINATION_MESSAGE = 'Customer deleted successfully.';
const REJECT_MESSAGE = 'Request has been rejected successfully.';
const REJECT_CHANGES_MESSAGE = 'Customer changes rejected.';
const ABANDON_MESSAGE = 'A request has been sent to abandon this customer.';
const TERMINATE_MESSAGE = 'A request has been sent to terminate this customer.';
const SAVE_ERROR = 'Failed to update request.';
const SUBMIT_ERROR = 'Failed to submit request.';
const APPROVE_CHANGES_ERROR = 'Failed to save customer.';
const APPROVE_TERMINATION_ERROR = 'Failed to delete customer.';
const REJECT_ERROR = 'Failed to reject request.';
const REJECT_CHANGES_ERROR = 'Failed request to reject changes.';
const ABANDON_ERROR = 'Failed to send abandon request.';
const TERMINATE_ERROR = 'Failed to send termination request.';

const defaultConfirmationDialogState = {
    open: false,
    msg: '',
    btnLabel: '',
    action: null,
};

const AddEditCustomer = ({ setTitle, config, actionInfos, setActionInfos }) => {
    const navigate = useNavigate();
    const { customerId } = useParams();
    const [customer, setCustomer] = useState(DefaultCustomer);
    const [refOptions, setRefOptions] = useState(DefaultCustomer.currentRequest.options);
    const [isDisplay, setDisplay] = useState(customerId > 0);
    const [confirmationDialogState, setConfirmationDialogState] = useState(defaultConfirmationDialogState);
    const { enqueueSnackbar } = useSnackbar();

    const setCustomerAndTitle = (newCustomer) => {
        if (newCustomer.customerID === null) {
            navigate("/");
        }
        else {
            setCustomer(newCustomer);
            const customerTypeChips = reverseTypes(newCustomer).filter(x => !!x);
            const title = <Box display={"flex"} gap={"16px"}>{newCustomer.currentRequest.options.companyName}<Box>{customerTypeChips}</Box></Box>
            setTitle(title);
            setRefOptions(newCustomer.currentRequest.options);
        }
    }

    useEffect(() => {
        if (customerId > 0) {
            getCustomer(customerId, setCustomerAndTitle);
        }
        else {
            setTitle(DefaultTitle);
        }
    }, [customerId]);

    useEffect(() => {
        return () => setTitle("");
    }, []);

    const updateCustomer = (newOptions) => {
        setCustomer({ ...customer, currentRequest: { ...customer.currentRequest, options: newOptions } });
    }

    const onData = (resp, msg) => {
        navigate(`/add-edit-customer/${resp.data?.customerID}`);
        setCustomerAndTitle(resp.data);
        enqueueSnackbar(msg, { variant: 'success' });
    }

    const onError = (error, msg) => {
        enqueueSnackbar(`${msg}:\n${error}`, { variant: 'error' });
    }

    const saveSubmit = () => {
        saveCustomer({ ...customer, type: resolveType(), status: Status.PENDING_CREATION_APPROVAL, lastUpdateUser: 'User' },
            (resp) => onData(resp, SUBMIT_MESSAGE),
            (error) => onError(error, SUBMIT_ERROR));
    }

    const saveIncomplete = () => {
        saveCustomer({ ...customer, type: resolveType(), status: Status.INCOMPLETE, lastUpdateUser: 'User' },
            (resp) => onData(resp, SAVE_MESSAGE),
            (error) => onError(error, SAVE_ERROR));
    }

    const approve = (requestedTypes) => {
        if (customer.status === Status.PENDING_TERMINATION_APPROVAL) {
            setConfirmationDialogState({
                open: true,
                msg: 'Are you sure you want to approve the customer termination?',
                btnLabel: 'Approve',
                action: executeApproveTerminate
            });
        }
        else {
            approveCustomer({ ...customer, lastUpdateUser: 'User' },
                requestedTypes,
                (resp) => onData(resp, APPROVE_CHANGES_MESSAGE),
                (error) => onError(error, APPROVE_CHANGES_ERROR));
        }
    }

    const reject = () => {
        rejectCustomer({ ...customer, lastUpdateUser: 'User' },
            (resp) => onData(resp, REJECT_MESSAGE),
            (error) => onError(error, REJECT_ERROR));
    }


    const rejectChanges = (requestedTypes) => {

        let revertRequestedTypeCount = customer.type;
        requestedTypes.forEach((sectionType) => {
            if (sectionType in Types) {
                const isActiveType = getOptionByType(customer, sectionType)?.active;
                revertRequestedTypeCount += isActiveType ? Types[sectionType] : -(Types[sectionType])
            }
        })
        customer.currentRequest.type = revertRequestedTypeCount;
        rejectChangesCustomer({ ...customer, type: revertRequestedTypeCount, lastUpdateUser: 'User' },
            requestedTypes,
            (resp) => onData(resp, REJECT_CHANGES_MESSAGE),
            (error) => onError(error, REJECT_CHANGES_ERROR));

    }

    const abandon = () => {
        if (customer.hasActiveRequest) {
            if (!customer.options?.companyName)
                customer.options.companyName = DefaultTitle;
            abandonCustomer({ ...customer, lastUpdateUser: 'User' },
                (resp) => onData(resp, ABANDON_MESSAGE),
                (error) => onError(error, ABANDON_ERROR));
        }
        else {
            deleteCustomer(customer.customerID,
                (resp) => {
                    enqueueSnackbar(APPROVE_TERMINATION_MESSAGE, { variant: 'success' });
                    navigate("/");
                },
                (error) => onError(error, APPROVE_TERMINATION_ERROR));
        }
    }

    const terminate = () => {
        setConfirmationDialogState({
            open: true,
            msg: 'Are you sure you want to terminate this customer?',
            btnLabel: 'Terminate',
            action: executeTerminate
        });
    }

    const executeTerminate = () => {
        terminateCustomer({ ...customer, lastUpdateUser: 'User' },
            (resp) => onData(resp, TERMINATE_MESSAGE),
            (error) => onError(error, TERMINATE_ERROR));
    }

    const executeApproveTerminate = () => {
        deleteCustomer(customer.customerID,
            (resp) => {
                enqueueSnackbar(APPROVE_TERMINATION_MESSAGE, { variant: 'success' });
                navigate("/");
            },
            (error) => onError(error, APPROVE_TERMINATION_ERROR));
    }

    const resolveType = () => {
        let type = 0;
        if (config?.permissions?.includes(Permissions.PARTCAT) && customer.currentRequest.options.partcat_options?.active) {
            type += Types.PartCat;
        }
        if (config?.permissions?.includes(Permissions.DM) && customer.currentRequest.options.dm_options?.active) {
            type += Types.DM;
        }
        if (config?.permissions?.includes(Permissions.VIO) && customer.currentRequest.options.vio_options?.active) {
            type += Types.VIO;
        }
        if (config?.permissions?.includes(Permissions.OE) && customer.currentRequest.options.oe_options?.active) {
            type += Types.OE;
        }
        if (config?.permissions?.includes(Permissions.PCO) && customer.currentRequest.options.pco_options?.active) {
            type += Types.PCO;
        }
        return type;
    }

    const closeConfirmationDialog = () => {
        setConfirmationDialogState(defaultConfirmationDialogState);
    }

    return (
        <div style={{ height: "100%" }}>
            <CustomerOptions
                customer={customer}
                setOptions={updateCustomer}
                isDisplay={isDisplay}
                setDisplay={setDisplay}
                saveSubmit={saveSubmit}
                saveIncomplete={saveIncomplete}
                approve={approve}
                reject={reject}
                rejectChanges={rejectChanges}
                abandon={abandon}
                terminate={terminate}
                refOptions={refOptions}
                setTitle={setTitle}
                config={config}
                actionInfos={actionInfos}
                setActionInfos={setActionInfos}
            />
            <ConfirmationDialog
                open={confirmationDialogState.open}
                setOpen={closeConfirmationDialog}
                id="terminate"
                textTitle={`Customer`}
                textContent={confirmationDialogState.msg}
                confirmButtonLabel={confirmationDialogState.btnLabel}
                cancelButtonLabel="Cancel"
                executeAction={confirmationDialogState.action}
            />
        </div>
    );
}

export default AddEditCustomer;