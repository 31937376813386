import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import FormTemplate from './FormTemplate';
import { PCOVCDBTypes,OnboardingRequired, PCOConfig } from '../../constants/DefaultOptions';
import { getAllAAIABrands } from '../../services/ApiService';

import { ReverseOptions } from '../../utils';
const PCOOptions = ({ options, setOption, errors, isDisplay, showCustomerOptions, customerOptions, defaultOptions }) => {

    const [aaiaBrands, setAAIABrands] = useState([]);

    useEffect(() => {
        getAllAAIABrands(d => setAAIABrands(d.reduce((acc, cur) => {
            acc[cur.id] = { label: cur.id, value: cur.id }
            return acc;
        }, {})));

    }, []);

    const PCOOptionsForm = [
        {
            headerTitle: PCOConfig.headerTitles.options,
            defaultExpanded: true,
            sections: [
                {
                    title: PCOConfig.active.title,
                    accessor: PCOConfig.active.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.contractLength.title,
                    accessor: PCOConfig.contractLength.accessor,
                    accessor2: PCOConfig.contractLength.accessor2,
                    type: "number",
                    input: "text",

                },
                {
                    title: PCOConfig.customerId.title,
                    accessor: PCOConfig.customerId.accessor,
                    type: "number",
                    input: "readonly",
                    accessorDep: PCOConfig.active.accessor,
                },
                {
                    title: PCOConfig.hasACES.title,
                    accessor: PCOConfig.hasACES.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.vcdbTypes.title,
                    accessor: PCOConfig.vcdbTypes.accessor,
                    input: "multiselect",
                    value: PCOVCDBTypes,
                    accessorDep: PCOConfig.active.accessor,
                },
                {
                    title: PCOConfig.hasPADB.title,
                    accessor: PCOConfig.hasPADB.accessor,
                    input: "checkbox",
                    accessorDep: PCOConfig.active.accessor,
                },
                {
                    title: PCOConfig.hasQDB.title,
                    accessor: PCOConfig.hasQDB.accessor,
                    input: "checkbox",
                    accessorDep: PCOConfig.active.accessor,
                },
            ]
        },
        {
            headerTitle: PCOConfig.headerTitles.setupInfo,
            sections: [
                {
                    title: PCOConfig.adminAccount.title,
                    accessor: PCOConfig.adminAccount.accessor,
                    input: "text",
                },
                {
                    title: PCOConfig.additionalAccounts.title,
                    accessor: PCOConfig.additionalAccounts.accessor,
                    input: "text",
                },
                {
                    title: PCOConfig.brandAAIA.title,
                    accessor: PCOConfig.brandAAIA.accessor,
                    input: "dropdown-virtualized",
                    value: aaiaBrands,
                    accessorDep: PCOConfig.active.accessor,
                },
                {
                    title: PCOConfig.expectedNumberOfParts.title,
                    accessor: PCOConfig.expectedNumberOfParts.accessor,
                    input: "text",
                },
                {
                    title: PCOConfig.onboardingRequired.title,
                    accessor: PCOConfig.onboardingRequired.accessor,
                    input: "radio",
                    value: OnboardingRequired,
                    reverse: ReverseOptions.pco_options.onboardingRequired,
                },
            ]
        },

        {
            headerTitle: PCOConfig.headerTitles.dataToLoad,
            sections: [
                {
                    title: PCOConfig.hasACESXML.title,
                    accessor: PCOConfig.hasACESXML.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.hasACESSpreadsheet.title,
                    accessor: PCOConfig.hasACESSpreadsheet.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.hasPIESXML.title,
                    accessor: PCOConfig.hasPIESXML.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.hasPIESSpreadsheet.title,
                    accessor: PCOConfig.hasPIESSpreadsheet.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.digitalAssets.title,
                    accessor: PCOConfig.digitalAssets.accessor,
                    input: "checkbox",
                },
                {
                    title: PCOConfig.conversionNeeded.title,
                    accessor: PCOConfig.conversionNeeded.accessor,
                    input: "checkbox",
                },
            ]
        },
        {
            headerTitle: "Additional Information",
            sections: [
                {
                    title: PCOConfig.additionalInfo.title,
                    accessor: PCOConfig.additionalInfo.accessor,
                    input: "multilinetext",
                }
            ]
        },
    ]

    return (
        <Box>
            <FormTemplate
                data={PCOOptionsForm}
                id="PCO"
                options={options}
                errors={errors}
                isDisplay={isDisplay}
                setOption={setOption}
                showCustomerOptions={showCustomerOptions}
                customerOptions={customerOptions}
                defaultOptions={defaultOptions}
            />
        </Box>
    );
}

export default PCOOptions;