import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';

export default (props) => {

  const {open, activeRequestTypes = [], handleConfirm, handleCancel} = props

  const [selectedRequestTypes, setSelectedRequestTypes] = useState(activeRequestTypes);
  const handleOnChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setSelectedRequestTypes([...selectedRequestTypes, value]);
    }
    else {
      setSelectedRequestTypes([...selectedRequestTypes.filter(x => x !== value)]);
    }
  }

  const onHandleConfirm = () => {
    handleConfirm(selectedRequestTypes);
    setSelectedRequestTypes([]);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Reject Changes for this Request?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the section you wish to reject.
        </DialogContentText>
        <div style={{display: 'flex', flexDirection: 'column', paddingTop: '8px', fontSize: '1rem'}}>
        {
          activeRequestTypes?.map((option) => 
          <FormControlLabel
            sx={{height: '20px'}}
            value={option}
            checked={selectedRequestTypes.includes(option)}
            onChange={handleOnChange}
            key={option}
            control={<Checkbox size='small' />}
            label={option}
          />)
        }
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>Cancel</Button>
        <Button onClick={onHandleConfirm} disabled={activeRequestTypes?.length !== 0 && selectedRequestTypes.length === 0}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}