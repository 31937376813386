import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const regionOptions = [
  { label: '' },
  { label: 'US/CAN' },
  { label: 'MEX' },
];

const yearsOptions = [{ label: '' }, ...Array.from({ length: 2030 - 1960 + 1 }, (_, index) => 1960 + index).reverse().map(x => ({label: `${x}`}))];

export default (isDisplay, handleRegion, handleYearFrom, handleYearTo, onRemoveRow, additionalColumns = []) => [
  {
    Header: 'ID',
    accessor: 'productLineId',
    minWidth: 75,
    width: 75,
    Cell: DisplayCell
  },
  {
    Header: 'Description',
    accessor: 'productLineName',
    width: 300,
    Cell: DisplayCell
  },
  {
    Header: 'Region',
    accessor: 'regions',
    minWidth: 120,
    width: 120,
    Cell: (props) => AutoCompleteCell(props, isDisplay, regionOptions, handleRegion),
  },
  {
    Header: 'Year From',
    accessor: 'yearFrom',
    minWidth: 85,
    width: 85,
    Cell: (props) => AutoCompleteCell(props, isDisplay, yearsOptions, handleYearFrom),
  },
  {
    Header: 'Year To',
    accessor: 'yearTo',
    minWidth: 85,
    width: 85,
    Cell: (props) => AutoCompleteCell(props, isDisplay, yearsOptions, handleYearTo),
  },
  ...additionalColumns,
  {
    Header: '',
    accessor: 'remove',
    minWidth: 50,
    width: 50,
    Cell: props => {
        if (isDisplay){
          return '';
        }
        return (
            <IconButton onClick={() => onRemoveRow(props.row.index)} sx={{ padding: 0.5 }}>
                <RemoveIcon sx={{ color: "#ff562b" }} />
            </IconButton>
        )
    },
    Filter: () => '',
  }
];

const AutoCompleteCell = (props, isDisplay, options, handleSelection) => {
  
  const initialValue = options.find(x => x.label === props.value) ?? {label: ''};
  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState(false);

  const updateValue = (event, value) => {
    setValue(value);
    handleSelection(value?.label, props.row.index);
    setEditMode(false);
  }

  const onBlur = () => setEditMode(false);

  const onClick = () => {
    setEditMode(true);
  }

  const fontColor = !props.row?.original?.rowStatus || props.row?.original?.rowStatus === 'normal' ? 'black' : props.row?.original?.rowStatus === 'deleted' ? 'red' : 'green';

  return (
  <div onClick={onClick} style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center'}}>
    {isDisplay || !editMode
      ? <div style={{color: fontColor}}>{props.value}</div>
      : <Autocomplete
        disablePortal
        size='small'
        value={value}
        onChange={updateValue}
        onBlur={onBlur}
        options={options}
        renderInput={(params) => <TextField {...params} size='small' variant="standard" inputProps={{...params?.inputProps, style: {...params?.inputProps?.style, fontSize: "0.8em" }}} />}
        sx={{
          width: '100%',
          '& .MuiSvgIcon-root': {
            fontSize: '1rem!important'
          }}}
        renderOption={(props, option) => (
          <li {...props}>
            <Typography style={{ fontSize: "0.8em" }}>{option.label}</Typography>
          </li>
        )}
        getOptionLabel={option => option.label}
      />
    }
  </div>)
}

const DisplayCell = (props) => {
  const fontColor = !props.row?.original?.rowStatus || props.row?.original?.rowStatus === 'normal' ? 'black' : props.row?.original?.rowStatus === 'deleted' ? 'red' : 'green';
  return <div style={{color: fontColor}}>{props.value}</div>
}