export function getEnumFlags(obj) {
    const isFlag = (arg) => {
        const nArg = Number(arg);
        const isNumber = !Number.isNaN(nArg);
        return isNumber && isPowerOfTwo(nArg);
    };

    const enumFlags = [];

    Object.entries(obj).forEach(entry => {
        const nKey = Number(entry[1]);
        if (isFlag(nKey)) {
            enumFlags.push(nKey);
        }
    });

    return enumFlags;
}

const isPowerOfTwo = (x) => {
    return x != 0 && (x & (x - 1)) == 0;
};