import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { reverseTypes } from '../utils';
import Divider from '@mui/material/Divider';
import { ProductsSummaryDefinitions } from '../constants/ProductSummaryDefinitions';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getToLocalStorage, setToLocalStorage } from 'jnpsoft-react-utilities/dist/InternalStorageService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    overflow: 'hidden'

};

const defaultState = {
    PartCat: true,
    DM: true,
    VIO: true,
    OE: true,
    PCO: true,
};

const activeTypes = {
    PartCat: false,
    DM: false,
    VIO: false,
    OE: false,
    PCO: false,
}

const keyProductSummary = "sales-portal_productSummaryChipsLabel";

const ProductsSummary = ({ open, handleClose, customer, companyName }) => {



    const customerTypeChips = reverseTypes(customer, { clickable: true }).filter(x => !!x);

    customerTypeChips.map((data, key) => {

        if (data.props.label in activeTypes) {
            activeTypes[data.props.label] = true
        }
    })
    const defaultToggleOption = getToLocalStorage(keyProductSummary) ?? defaultState;
    const [toggleOption, setToggleOption] = useState(defaultToggleOption);
    const handleToggleOption = (option) => {
        const newToggleOption = { ...toggleOption, [option]: !toggleOption[option] };

        setToLocalStorage(keyProductSummary, newToggleOption)
        setToggleOption(newToggleOption);
    };

    const GenerateProductSummaryOption = (key, customerOption, selectedOption) => {

        const data = customerOption[selectedOption];

        return activeTypes[key] ?
            <Box sx={{ display: toggleOption[key] ? "inherit" : "none" }}>
                <Grid container sx={{ padding: 1 }} >
                    <Grid item xs={12}>
                        <Typography variant="h5">{key}</Typography>
                    </Grid>
                    <Grid container >
                        {key in ProductsSummaryDefinitions && ProductsSummaryDefinitions[key].map((config, configKey) => {
                            return (
                                Object.entries(data).filter(x => x[0] in config.sections && !!x[1] && (!Array.isArray(x[1]) || (Array.isArray(x[1]) && x[1].length > 0))).length > 0
                                    ? <Grid item key={configKey} xs={config.gridSize ? config.gridSize : 3} sx={{ padding: 0.5 }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{config.headerTitle}</Typography>
                                            </Grid>
                                            {
                                                Object.entries(config.sections).map(([keySection, section]) => {

                                                    return ((!!data[section.accessor] && !Array.isArray(data[section.accessor])) ||
                                                        Array.isArray(data[section.accessor]) && data[section.accessor]?.length > 0 ?

                                                        <Grid item xs={12} key={keySection}>
                                                            {section.title && section.title != "" ? <Typography display="inline" variant="subtitle2" sx={{ fontSize: "0.85rem" }}>
                                                                {section.title} :
                                                            </Typography> : ""}
                                                            &nbsp;
                                                            <Typography component={'span'} display="inline" sx={{ fontSize: "0.85rem" }}>{section.reverse ? section.reverse(data[section.accessor]) : Array.isArray(data[section.accessor]) ?
                                                                data[section.accessor].join(', ') : data[section.accessor].toString()}
                                                            </Typography>
                                                        </Grid>
                                                        : "")
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    : ""
                            )
                        }
                        )}

                    </Grid>
                </Grid>
                <Divider />
            </Box>
            : ""

    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid container item xs={10}>
                            <Grid item xs={4}>
                                <Typography variant="h4">{companyName}</Typography>
                            </Grid>
                            <Grid container item xs={8}>
                                {customerTypeChips.map((data, key) => {
                                    return <Button key={key} sx={{ '& > div.MuiChip-root': !toggleOption[data?.key] ? { background: "rgba(0, 0, 0, 0.08)" } : "" }} onClick={() => handleToggleOption(data.props.label)}>{data}</Button>
                                })
                                }
                            </Grid>

                        </Grid>

                        <Grid item xs={2} align="right">
                            <Button disabled>Send Via email</Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box style={{ overflowY: "auto", height: `calc(100% - 42px)` }}>

                        {GenerateProductSummaryOption("PartCat", customer.options, 'partcat_options')}

                        {GenerateProductSummaryOption("DM", customer.options, 'dm_options')}

                        {GenerateProductSummaryOption("VIO", customer.options, 'vio_options')}

                        {GenerateProductSummaryOption("OE", customer.options, 'oe_options')}

                        {GenerateProductSummaryOption("PCO", customer.options, 'pco_options')}
                    </Box>
                </Box>
            </Modal >

        </div >
    );
}

export default ProductsSummary;