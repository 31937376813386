import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import { getOeProducts } from '../services/ApiService';
import ProductLineTable from './customer-options/ProductLineTable';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: 'calc(95% - 80px)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
};

const ProductsChooser = ({open, initialSelection, handleClose = () => {}, handleSelection = () => {}, availableOeProducts = null}) => {

    const [oeProducts, setOeProducts] = useState([]);
    const [selection, setSelection] = useState(initialSelection ?? []);

    useEffect(() => {
        if (availableOeProducts) {
            setOeProducts(availableOeProducts);
        } else {
            getOeProducts(setOeProducts);
        }
    }, []);

    useEffect(() => {
        if (open) {
            setSelection(initialSelection);
        }
    }, [open])

    const regionAndYears = 
        availableOeProducts 
        ? [
            {
                Header: 'Regions',
                accessor: 'regions',
                minWidth: 175,
                width: 175,
            },
            {
                Header: 'Year From',
                accessor: 'yearFrom',
                minWidth: 100,
                width: 100,
            },
            {
                Header: 'Year To',
                accessor: 'yearTo',
                minWidth: 100,
                width: 100,
            },
        ]
        : [];
    
    const columnDefs = [
        {
            Header: 'ID',
            accessor: 'productLineId',
            minWidth: 75,
            width: 75,
        },
        {
            Header: 'Description',
            accessor: 'productLineName',
            minWidth: 175,
            width: 175,
        },
        ...regionAndYears,
        {
            Header: '',
            accessor: 'add',
            minWidth: 70,
            width: 70,
            Cell: props => {
                return (
                    <IconButton onClick={() => addRow(props.row.original)} sx={{ padding: 0.5 }}>
                        <AddIcon sx={{ color: "#ff562b" }} />
                    </IconButton>
                )
            },
            Filter: () => '',
        }
    ];

    const addRow = (row) => {
        const newRow = {
            productLineId: row?.productLineId,
            productLineName: row?.productLineName,
            regions: row?.regions ?? '',
            yearFrom: row?.yearFrom ?? '',
            yearTo: row?.yearTo ?? '',
        };
        setSelection([...selection, newRow]);
    }

    const handleRegion = (value, index) => handleCurrentSelection(value, index, 'regions');
    const handleYearFrom = (value, index) => handleCurrentSelection(value, index, 'yearFrom');
    const handleYearTo = (value, index) => handleCurrentSelection(value, index, 'yearTo');
    const onRemoveRow = (index) => {
        const newSelection = [...selection];
        newSelection.splice(index, 1);
        setSelection(newSelection);
    }

    const handleCurrentSelection = (value, index, fieldName) => {
        const newSelection = [...selection];
        newSelection[index] = {...newSelection[index], [fieldName]: value};
        setSelection(newSelection)
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>Please click on a cell to edit its value</Typography>
                    <div 
                        style={{
                            height: 'calc(100% - 80px)',
                            minHeight: '112px',
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '8px',
                            }}>
                        <div style={{width: '49%', height: '100%'}}>
                            <ProductLineTable 
                                products={selection} 
                                title='Your Selection'
                                isDisplay={false}
                                handleRegion={handleRegion}
                                handleYearFrom={handleYearFrom}
                                handleYearTo={handleYearTo}
                                onRemoveRow={onRemoveRow}/>
                        </div>
                        <div style={{width: '49%', height: '100%'}}>
                            <DivTableStyles>
                                <JnpDivTable
                                    columns={columnDefs}
                                    data={oeProducts}
                                    defaultSortColumn="productDescription"
                                    title="Available OE Products"
                                    backGroundColor="#003061"
                                    sx={{padding:0}}
                                    selectedBackgroundColor='white'
                                />
                            </DivTableStyles>
                        </div>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                        <Button variant="contained" sx={{backgroundColor:'#ff562b',color:'white'}} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{backgroundColor:'#ff562b',color:'white'}} onClick={() => handleSelection(selection)}>
                            Finish
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ProductsChooser;