import React, { useEffect, useState } from 'react';
import { configureActionBar, executeAction, configureDisabled } from '../../services/ActionBarService';
import JnpDivTable from 'jnpsoft-react-utilities/dist/JnpTable/JnpDivTable';
import { DivTableStyles } from 'jnpsoft-react-utilities/dist/JnpTable/Styles';
import { getAllCustomers, downloadLicenseFilesZip, downloadOELicenseFilesZip } from '../../services/ApiService';
import { useNavigate } from "react-router-dom";
import { AddEditCustomerRoute } from '../routes';
import { 
    ActionBarHeight, 
    createAccessorStatus, 
    FooterHeight, 
    reverseTypes, 
    reverseTypesCSV,
    createFilterDescription, 
    TabsHeight,
    downloadFile } from '../../utils';
import { Permissions } from '../../models/user-profile';
import { convertObjectFieldISOtoLocal } from '../../utils/time_utc_local';
import { Types, requestStatusDescription } from '../../constants/DefaultOptions';
import GeneralCheckBox from '../../components/GeneralCheckBox';
import Box from '@mui/material/Box';
import '../../styles/toolbar.css';

const Customers = ({actionInfos, setActionInfos, config}) => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const [showHiddenCustomer, setShowHiddenCustomer] = useState({value: false});

    const createCustomer = () => navigate(`${AddEditCustomerRoute}/-1`);
    const download = () => downloadLicenseFilesZip((blob) => downloadFile(blob, `AutoCare_license.zip`));
    const downloadOEFile = () => downloadOELicenseFilesZip((blob) => downloadFile(blob, `OE_license.zip`));

    const actions = [
        {
            label: "Create Customer",
            name: "create-customer",
            onAction: createCustomer,
        },
        {
            label: "Download OE License Files",
            name: "download-oe-license-file",
            onAction: downloadOEFile,
        },
        {
            label: "Download PCO License Files",
            name: "download-pco-license-file",
            onAction: download,
        }
    ];

    useEffect(() => {
        getAllCustomers((resData) => {
            const transData = resData.map(convertObjectFieldISOtoLocal('date')).map(convertObjectFieldISOtoLocal('lastUpdate'));
            setCustomers(transData);
        });
    }, []);

    useEffect(() => {
        return configureActionBar(actions, actionInfos, setActionInfos);
    }, []);

    useEffect(() => {
        const action = actions.find(x => x.name === actionInfos.actionClicked);
        if (action && action.onAction) {
            executeAction(() => action.onAction(), actionInfos, setActionInfos)
        }
    }, [actionInfos.actionClicked]);

    useEffect(() => {
        const enabled = config?.permissions?.includes(Permissions.USER) || config?.permissions?.includes(Permissions.MANAGER);
        const disabled = enabled ? !enabled : true;
        configureDisabled("create-customer", disabled, actionInfos, setActionInfos);
        configureDisabled("download-pco-license-file", !!!config?.permissions?.includes(Permissions.MANAGER), actionInfos, setActionInfos);
        configureDisabled("download-oe-license-file", !!!config?.permissions?.includes(Permissions.MANAGER), actionInfos, setActionInfos);
    }, [config, actionInfos]);
      
    const columnDefs = [
        {
            Header: 'Status',
            accessor: 'status',
            Cell: props => {
                return (
                    createAccessorStatus(props?.row?.original)
                )
            },
            sortType: (a, b) => {
                const aValue = a?.values?.Status?.props?.children;
                const bValue = b?.values?.Status?.props?.children;
                return `${aValue}`.localeCompare(`${bValue}`);
            },
            csvGetter:  props => {
                return props?.status && props?.status in requestStatusDescription ? requestStatusDescription[props.status] : ""
            },
            filter: createFilterDescription(requestStatusDescription, (o) => o.status)
        },
        {
            Header: 'Name',
            accessor: 'options.companyName',
            Cell: ({row}) => row.original?.currentRequest?.options?.companyName || row.original?.options?.companyName,
            csvGetter:  (props) => props?.currentRequest?.options?.companyName || props?.options?.companyName,
        },
        {
            Header: 'Type',
            accessor: 'type',
            Cell: props => { 
                return reverseTypes(props?.row?.original)
            },
            filter: (rows, fieldName, fragment) => {
                const types = Object.keys(Types);
                return rows.filter( (r) => {
                    const tys = types.filter((t) => t.includes(fragment));
                    return tys.find((t) => (r.original.type & Types[t]) === Types[t]);
                });
            },
            csvGetter:  props => {     
                return props?.type ? reverseTypesCSV(props) : "";
            },
        },
        {
            Header: 'ID',
            accessor: 'customerID',  
        },
        {
            Header: 'Options',
            accessor: 'currentRequest.options.companyAddress',
            Cell: ({row}) => row.original?.currentRequest?.options?.companyAddress || row.original?.options?.companyAddress,
            csvGetter:  (props) => props?.currentRequest?.options?.companyAddress || props?.options?.companyAddress,
            
        },
        {
            Header: 'Last Modified',
            accessor: 'lastUpdate',
        }
    ];

    const setSelectedRows = (selectedFlatRows) => { };

    const rowOnClick = (e, i, v) => {
        navigate(`${AddEditCustomerRoute}/${v.original.customerID}`);
    }

    return (
        <React.Fragment>
            <Box position='absolute' top="0">
                <GeneralCheckBox 
                    keyPropertyName="value"
                    label="Show Hidden Customer"
                    itemValue={showHiddenCustomer}
                    setItemValue={setShowHiddenCustomer}
                />
            </Box>
            <DivTableStyles style={{ height: `calc(100% - (${ActionBarHeight}px + ${TabsHeight}px + ${FooterHeight}px))` }}>
                <JnpDivTable
                    columns={columnDefs}
                    data={customers.filter(x => !!showHiddenCustomer?.value || !x.options?.isCustomerHidden)}
                    selectable={false}
                    disableSelect={true}
                    setSelectedRows={setSelectedRows}
                    rowOnClick={rowOnClick}
                    keyPropertyId='customerID'
                    defaultSortColumn="lastUpdateUser"
                    title="Customers"

                    backGroundColor="#003061"
                />
            </DivTableStyles>
        </React.Fragment>
    );
}

export default Customers;