const Permissions = {
    MANAGER: 'manager',
    USER: 'user',
    PARTCAT: 'partcat',
    DM: 'dm',
    VIO: 'vio',
    OE: 'oe',
    PCO: 'pco',
};

export {
    Permissions,
};