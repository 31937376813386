import React, { useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import FormTemplate from './FormTemplate';
import ProductsChooser from '../ProductsChooser';
import { DMConfig, OEConfig, OECustomerType } from '../../constants/DefaultOptions';
import DMOptionsForm from './form-definitions/DMOptionsForm';

const DMOptions = ({ options, setOption, errors, isDisplay, showCustomerOptions, customerOptions, oeOptions }) => {
    const [state, setState] = useState({open: false, accessor: ''});
    
    const handleOpen = (accessorValue) => setState({open: true, accessor: accessorValue});

    const handleClose = () => {
        setState({ open: false, accessor: '' });
    };

    const handleSelection = (selection) => {
        const updatedOpt = {
            ...options,
            [state.accessor]: selection
        };
        setOption(updatedOpt);
        handleClose();
    }

    const handleDmOeProductLineCheckboxOnchange = (e, index, accessor) => {
        const checked = e.target.checked;
        const products = options[DMConfig.oeDataProductLines.accessor];
        const productObj = JSON.parse(JSON.stringify(products));
        productObj[index] = {...productObj[index], [accessor] : checked};
        const updatedObj = { ...options, [DMConfig.oeDataProductLines.accessor] : productObj };
        setOption(updatedObj);
    }

    const isFull = options?.[DMConfig.oeCustomerType.accessor] === OECustomerType.Full.value;
    const availableOeProducts = oeOptions?.[OEConfig.productLines.accessor] ?? [];

    const additionalColumns = [
        {
            Header: 'Carry Over',
            accessor: 'carryOver',
            minWidth: 85,
            width: 85,
            Cell: ({row}) => 
                <Checkbox 
                    size='small'
                    disabled={isFull || isDisplay}
                    checked={isFull || !!row.original.carryOver}
                    onChange={(e) => handleDmOeProductLineCheckboxOnchange(e, row.index, 'carryOver')}
                />
        },
        {
            Header: 'Shopping List',
            accessor: 'shoppingList',
            minWidth: 100,
            width: 100,
            Cell: ({row}) => 
                <Checkbox 
                size='small'
                disabled={isFull || isDisplay}
                checked={isFull || !!row.original.shoppingList}
                onChange={(e) => handleDmOeProductLineCheckboxOnchange(e, row.index, 'shoppingList')}
            />
        }
    ];

    return (
        <Box>
            <FormTemplate
                data={DMOptionsForm(handleOpen, additionalColumns)}
                id="DM"
                options={options}
                errors={errors}
                isDisplay={isDisplay}
                setOption={setOption}
                showCustomerOptions={showCustomerOptions}
                customerOptions={customerOptions}
            />
            <ProductsChooser 
                initialSelection={options[state.accessor]} 
                open={state.open} 
                handleClose={handleClose} 
                handleSelection={handleSelection} 
                availableOeProducts={availableOeProducts}/>
        </Box>
    )
}

export default DMOptions;