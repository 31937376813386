import { Box, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

const GeneralTextField = (props) => {

    const {
        keyPropertyName,
        itemValue,
        type,
        setItemValue,
        handleError = () => false,
        getHelperText = () => '',
        containerStyle = {},
        ...otherProps } = props;

    const [value, setValue] = useState(itemValue?.[keyPropertyName] ?? '')
    const handleChange = (e) => setValue(e.target.value);
    const handleDateChange = (e) => { 
        setValue(e.target.value); 
        onSetItemValue(e.target.value); 
    }
    const handleBlur = () => onSetItemValue(value);
    const onSetItemValue = (value) => {
        const updatedObj = { ...itemValue, [keyPropertyName]: value };
        setItemValue(updatedObj);
    }
    const id = `${keyPropertyName}-id`;

    useEffect(() => {
        if (itemValue?.[keyPropertyName] !== value) {
            setValue(itemValue?.[keyPropertyName]);
        }
    }, [itemValue])
   
    return (
        <Box sx={{...containerStyle }}>
            <TextField
                id={id}
                value={value ?? ''}
                onChange={type === 'date' ? handleDateChange : handleChange}
                onBlur={handleBlur}
                color="primary"
                variant="outlined"
                size="small"
                type={type}
                fullWidth
                error={handleError(keyPropertyName)}
                helperText={getHelperText(keyPropertyName)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    style: {
                      padding: '4px'
                    }
                 }}
                {...otherProps}
            />
        </Box >
    );
}

export default GeneralTextField;