import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import GeneralTextField from "../GeneralTextField";


export const EditBarWidth = 400;

const AddEditContact = ({ options, updateContacts, status, setStatus, customerOptions }) => {

    const [disableSave, setDisableSave] = useState(true);

    const onEditClose = () => {
        setStatus({ open: false, contactToEdit: {}, index: -1 });
        setDisableSave(true);
    }

    const saveContact = () => {
        var newContact = [...options.contacts];
        if (status.index === -1) {
            const allIds = [...options.contacts.map(c => isNaN(c.id) ? 0 : c.id), ...customerOptions.contacts.map(c => isNaN(c.id) ? 0 : c.id)];
            var newId = 0;
            allIds.forEach(id => {
                if (id > newId) {
                    newId = id;
                }
            });
            newContact.push({...status.contactToEdit, id: newId + 1});
        }
        else {
            newContact[status.index] = status.contactToEdit;
        }

        updateContacts(newContact);
        setStatus({ open: false, contactToEdit: {}, index: -1 });
        setDisableSave(true);
    }

    const updateContact = (newContact) => {
        const disabled = Object.keys(newContact).reduce((a, b) => a && !(newContact[b]?.length > 0), true);
        setDisableSave(disabled);
        setStatus({ ...status, contactToEdit: newContact });
    }

    return (
        <Drawer
            anchor='right'
            open={status.open}
            variant="persistent"
            sx={{
                '& .MuiDrawer-paper': {
                    width: `${(status.open ? EditBarWidth : 0)}px`,
                    backgroundColor: '#F5F5F5',
                    transition: theme => theme.transitions.create(['width', 'margin'], {
                        easing: 'transitions.easing.sharp',
                        duration: 'transitions.duration.enteringScreen',
                    })
                },
            }}
        >
            <div style={{ height: "64px" }}></div>
            <div style={{
                padding: '16px',
                overflowY: 'auto',
                paddingBottom: '56px',
            }}>
                <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', paddingBottom: 2 }}>
                    <Typography variant="h5">
                        New Contact
                    </Typography>
                    <IconButton color="inherit" aria-label="close edit" component="span" sx={{ padding: 0 }} onClick={onEditClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GeneralTextField
                                keyPropertyName="name"
                                label="Name"
                                itemValue={status.contactToEdit}
                                setItemValue={updateContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField
                                keyPropertyName="type"
                                label="Type"
                                itemValue={status.contactToEdit}
                                setItemValue={updateContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField
                                keyPropertyName="email"
                                label="Email"
                                itemValue={status.contactToEdit}
                                setItemValue={updateContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GeneralTextField
                                keyPropertyName="phone"
                                label="Phone #"
                                itemValue={status.contactToEdit}
                                setItemValue={updateContact}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={saveContact} disabled={disableSave}>
                                Save Contact
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Drawer>
    );
}

export default AddEditContact;