import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Customers from './customers/Customers';
import AddEditCustomer from './customers/add-edit-customer/AddEditCustomer';


export const AddEditCustomerRoute = "/add-edit-customer";

export const HomeLink = {route: "/", name: "Customers", icon: () => <RotateLeftIcon />, target: () => <Customers />}

export const Routes = [
    {route: `${AddEditCustomerRoute}/:customerId`, target: () => <AddEditCustomer />},
];