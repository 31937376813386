import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const ToolbarProductList = (props) => {

    const {
        accessor,
        onEdit,
    } = props;

    const handleEdit = () => {
        onEdit(accessor);
    }

    return (
        <Toolbar variant="dense" disableGutters={true} sx={{ minHeight: "initial", border: "none", flex: 1, paddingBottom: '4px' }}>
            <Typography
                sx={{
                    flex: '1 1 100%',
                    color: 'black',
                    fontWeight: 'bold'
                }}
                variant="subtitle"
                id="tableTitle"
                component="div"
            >
            </Typography>

            <Tooltip title="Manage Product Lines">
                <Button variant='outlined' onClick={handleEdit} sx={{width: '300px'}}>
                    Manage Products
                </Button>
            </Tooltip>
        </Toolbar>


    );
}

export default ToolbarProductList;