import { Box, RadioGroup, FormControl } from '@mui/material';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import React from 'react';

const GeneralRadioGroup = (props) => {

    const {
        keyPropertyName,
        label = '',
        values,
        itemValue,
        setItemValue,
        containerStyle = {},
        defaultValue,
        ...otherProps } = props;

;
    const handleChange = (event) => {
        const val = event.target.value;
        const updatedObj = { ...itemValue, [keyPropertyName]: Number(val) };
        setItemValue(updatedObj);
    };

    const name = `radio-button-${keyPropertyName}`;
  

    return (
        <FormControl>
            <RadioGroup
                row
                key={name}
                value={keyPropertyName in itemValue && itemValue[keyPropertyName] !== null ? itemValue[keyPropertyName] : defaultValue}
                name="radio-buttons-group"
                onChange={handleChange}
            >
                {Object.values(values).map((val, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.775rem',
                                ...containerStyle
                            }}>
                            <Radio
                                name={val.label}
                                value={val.value}

                                size="small"
                                sx={{
                                    padding: '0!important'
                                }}
                                {...otherProps}
                            />
                            <Typography variant="body2" style={{ padding: '0 8px' }}>{val.label}</Typography>
                        </Box>
                    )
                })
                }
            </RadioGroup>
        </FormControl>
    );
}

export default GeneralRadioGroup;